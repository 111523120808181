<template>
    <main :class="displayClass">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'"></bs-page-title>
        <div class="w100">
            <form @submit.prevent>
              <div class="row mb-3">
                <div class="col">
                  <label for="Label" class="form-label">名称</label>
                  <input type="text" class="form-control" id="Label" placeholder="部署名" v-model="department.label">
                </div>
              </div>
              <!-- <div class="row mb-5">
                <div class="col">
                  <label for="ArticleListPrice" class="form-label">グループ</label>
                  <bs-select
                    :options="tag_group"
                    v-model="tag.group_id"></bs-select>
                </div>
                <div class="col">
                </div>
               </div> -->
              <div class="row mb-3">
                <div class="col-12 col-sm-4 mb-2">
                  <router-link to="/department/list" class="btn btn-light btn-outline-secondary">戻る</router-link>
                </div>
                <div class="col-12 col-sm-4 mb-2 text-center">
                  <button type="submit" @click="update" class="btn btn-primary">更新</button>
                </div>
              </div>
            </form>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
// import BsSelect from '../../atoms/BsSelect.vue'
// import BsCheckBox from '../../atoms/BsCheckBox.vue'
// import '../../assets/bootstrap/css/sc.css';

export default {
  name: 'TagDetailView',
  data: function () {
      return {
        'targetid': this.$route.params.id,
        'fromParentTitle': '部署データ',
        'fromParentBtn': '',
        'department': {},
      }
  },
  components: {
      BsPageTitle,
      // BsSelect,
      // BsCheckBox,
  },
  methods: {
      update(){
        // console.log('component methods', this.department);
        return this.$store.dispatch('updateDepartment', {data: this.department })
          .then(() => {
            // this.back()
          })
          .catch(err => Promise.reject(err))
      }
  },
  computed: mapState({
    // tag_group: state => state.apidata.tag_group
      // tags_list: state => state.tags.lists
  }),
  created () {
      if(Number.isNaN(this.targetid)){
        this.$router.push({ path: '/' })
      }
      const ret = !Number.isNaN(this.targetid)
        ? {...this.$store.getters.getDepartmentById(this.targetid)}
        : {};
      this.department = ret;
  },
  mounted: function(){
      // console.log(this.$route.path);
      // console.log('strage', this.$store.state.article);
      // console.log('this', ...this.$store.getters.getArticleById(1));
      this.fromParentTitle = '部署データ詳細';
      // console.log('tags_list', this.tags_list);
  },

  props: {
        displayClass: {
            type: String,
            default: 'col-md-9 ms-sm-auto col-lg-10 px-md-4'
        },
    },

}
</script>
