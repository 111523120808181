<template>
    <main :class="displayClass">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'" :btnToLink="'/template/add'" :routerSw="true"></bs-page-title>
        <div class="w100">
            <table class="table" v-if="Object.keys(lists).length > 0">
                <thead>
                    <tr>
                        <th style="width:40%;">発注書名</th>
                        <th style="width:20%;">発注元</th>
                        <th style="width:20%;">受注元</th>
                        <th colspan="2" style="width:20%;">action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(list, index) in lists" :key="list.id">
                        <td>{{ list.label }}</td>
                        <td>{{ list.orderer_label }}</td>
                        <td>{{ list.contractor_label }}</td>
                        <td>
                            <router-link :to="{name: 'template_detail', params:{id:list.id}}" class="btn btn-light btn-outline-secondary btn-sm" href="#">詳細</router-link>
                        </td>
                        <td>
                            <button @click="delData(list.id, index)" class="btn btn-danger btn-sm" href="#">削除</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-else>
                <p class="alert alert-warning">
                    データがありません。
                </p>
            </div>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
import Common from '../../../common/common'
// import '../../assets/bootstrap/css/sc.css';

export default {
    name: 'CotListView',
    data: function () {
        return {
            'fromParentTitle': '発注書テンプレート一覧',
            'fromParentBtn': 'テンプレート新規登録',
            'email': '',
            'pass': '',
            error: false
        }
    },
  props: {
        displayClass: {
            type: String,
            default: 'col-md-9 ms-sm-auto col-lg-10 px-md-4'
        },
    },
    components: {
        BsPageTitle,
    },
    computed: mapState({
        lists: state => state.apidata.template
    }),

    created () {
        this.loadTemplate();
    },
    mounted: function(){
        // console.log(this.$route.path);
        // console.log('strage', this.$store.state.article, this.lists);
        // this.fromParentTitle = '商品詳細';
    },

    methods: {
        delData(target, index){
            if(confirm('削除しますか？')){
                const data = !Number.isNaN(target)
                ? {...this.$store.getters.getBasicById(target, 'template')}
                : {};
                // console.log('ret ticket data' ,ticket);
                data['stat'] = 9;
                return this.$store.dispatch('updateBasic', {data: data , target: 'template'})
                    .then(() => {
                        this.alertMessage = '削除しました';
                        this.alertClass = 'alert-success';
                        Common.moveToTop();
                    })
                    .catch((err) => {
                        // Promise.reject(err)
                        console.log(err);
                        this.alertMessage = '削除できませんでした';
                        this.alertClass = 'alert-danger';
                        Common.moveToTop();
                    })
                    .finally(() => {
                        // delete this.lists[index];
                        // this.lists.slice(index, 1);
                        console.log('delete finally', index);
                        this.loadTemplate();
                    });
            }
        },
        loadTemplate () {
            // console.log('tag');
            const param = {
                'parent_id': 0
            }
            return this.$store.dispatch('fetchBasicLists', {data: param, target: 'template'})
                        .catch(err => Promise.reject(err))
                        .then(() => {
                            console.log('loard complete template');
                            // console.log('this', this.$store.state.apidata.department);
                            // this.$router.push({ path: '/login' })
                        })
                        .finally(() => {
                            console.log('finally', this.$store.state.apidata.template);
                        })
        },
        // loadGroups () {
        //     // console.log('tag');
        //     return this.$store.dispatch('fetchTagGroupLists', {data: {}})
        //                 .catch(err => Promise.reject(err))
        //                 .then(() => {
        //                     console.log('loard complete tag');
        //                     console.log('thistg', this.$store.state.apidata.tag_group);
        //                     // this.$router.push({ path: '/login' })
        //                 })
        //                 .finally(() => {
        //                     console.log('finallytg', this.$store.state.apidata.tag_group);
        //                 })
        // }
    }

}
</script>
