<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'"></bs-page-title>
        <bs-msg-alert :alertClass="alertClass">{{this.alertMessage}}</bs-msg-alert>
        <div class="w100">
            <Form :validation-schema="schema" v-slot="{errors}" @submit.prevent novaformate>
              <div class="row mb-3">
                <div class="col">
                  <label for="OrderStepLabel" class="form-label">名称</label>
                  <Field v-model="order_step.label"  name="label" placeholder="工程名称" class="form-control" id="OrderStepLabel" />
                  <span class="validate_error_msg">{{errors.label}}</span>
                </div>
                <div class="col">
                  <label for="OrderStepSort" class="form-label">順序</label>
                  <Field v-model="order_step.sort" type="number" name="sort" placeholder="順序" class="form-control" id="OrderStepSort" />
                  <span class="validate_error_msg">{{errors.sort}}</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-sm-4 mb-2">
                  <router-link to="/order_step/list" class="btn btn-light btn-outline-secondary">戻る</router-link>
                </div>
                <div class="col-12 col-sm-4 mb-2 text-center">
                  <button type="submit" @click="update" class="btn btn-primary">{{btnLabel}}</button>
                  <!-- <bs-button :btnClass="'btn-primary'" :disabled="Object.keys(errors).length" @to_click_event="handleClick">{{btnLabel}}</bs-button> -->
                  <!-- <button type="submit" @click="update" class="btn btn-primary">{{btnLabel}}</button> -->
                </div>
              </div>
            </Form>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
// import BsSelect from '../../atoms/BsSelect.vue'
import { Field, Form } from 'vee-validate';
import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
// import BsCheckBox from '../../atoms/BsCheckBox.vue'
// import '../../assets/bootstrap/css/sc.css';

export default {
  name: 'OrderStepDetailView',
  data: function () {
      return {
        'targetid': this.$route.params.id,
        'fromParentTitle': '工程データ',
        'fromParentBtn': '',
        'add': true,
        'alertMessage': '',
        'alertClass': 'alert-info',
        'order_step': {},
        'schema': {
          label: 'required'
        }
      }
  },
  components: {
      BsPageTitle,
      BsMsgAlert,
      // BsSelect,
      Field,
      Form,
      // BsCheckBox,
  },
  methods: {
    update(){
      console.log('component methods', this.order_step);
      // this.device.receipt_logo_image = this.uploadFile;
      // console.log('upload upload receipt', this.uploadFile, this.device.receipt_logo_image);
      return this.$store.dispatch('updateBasic', {data: this.order_step , target: 'order_step'})
        .then(() => {
          // this.back()
          this.alertMessage = this.btnLabel + 'しました';
          this.alertClass = 'alert-success';
        })
        .catch(
          () => {
          err => Promise.reject(err);
            this.alertMessage = this.btnLabel + 'できませんでした';
            this.alertClass = 'alert-danger';
          }
        )
    }
      // handleClick(){
      //   console.log('component methods', this.tag);
      //   return this.$store.dispatch('updateTag', {data: this.tag })
      //     .then(() => {
      //       // this.back()
      //     })
      //     .catch(err => Promise.reject(err))
      // }
  },
  computed: mapState({
    // tag_group: state => state.apidata.tag_group,
    btnLabel() {
        if(this.add){
            return '新規登録';
        }
        else{
            return '更新';
        }
    },
      // tags_list: state => state.tags.lists
  }),
  created () {

    if(this.targetid == 'add'){
      // console.log('add');
      // console.log('this.article', this.article);
      this.order_step = {
        'label': '',
      };
      // console.log('this.type', this.type);
    }else{
      const ret = !Number.isNaN(this.targetid)
          ? {...this.$store.getters.getBasicById(this.targetid, 'order_step')}
        // ? {...this.$store.getters.getorder_stepById(this.targetid)}
        : {};
      if(Object.keys(ret).length > 0){
        this.add = false;
      }
      this.order_step = ret;
    }

      // if(Number.isNaN(this.targetid)){
      //   this.$router.push({ path: '/' })
      // }
      // const ret = !Number.isNaN(this.targetid)
      //   ? {...this.$store.getters.getTagById(this.targetid)}
      //   : {};
      // if(Object.keys(ret).length > 0){
      //   this.add = false;
      // }
      // this.tag = ret;
  },
  mounted: function(){
      // console.log(this.$route.path);
      // console.log('strage', this.$store.state.article);
      // console.log('this', ...this.$store.getters.getArticleById(1));
      this.fromParentTitle = '商品仕入先';
      this.alertMessage = this.btnLabel + 'できます';
      // console.log('tags_list', this.tags_list);
  },

  // computed: {
  //   article () {
  //     // const id = parseInt(this.$route.params.id);
  //     // console.log(id, 'idid');
  //     console.log('ret', ret);
  //     return ret;
  //   }
  // },
  props: {
    // article: {
    //   type: Object,
    //   required: true,
    //   default: () => {}
    // },
    onupdate: {
      type: Function,
      required: true
    },
  }

}
</script>
