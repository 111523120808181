function getToday(){
    // const now = new Date();
    return getNowYm(true) + '-' + getDay(true);
}
function getTodayNum(){
    const now = new Date();
    const date = String(now.getFullYear()) + String(getMonth(true)) + String(getDay(true));
    return date;
}
function getMonth(strSw){
    const now = new Date();
    let month = now.getMonth() + 1;
    if(strSw == true){
        if(month < 10){
            month = '0' + month;
        }
    }
    return month;
}
function getDay(strSw){
    const now = new Date();
    let day = now.getDate();
    if(strSw == true){
        if(day < 10){
            day = '0' + day;
        }
    }
    return day;
}
function getNowYm(strSw){
    const now = new Date();
    return now.getFullYear() + '-' + getMonth(strSw)
}
function getDayLast(){
    const now = new Date();
    // console.log('now.getFullYear', getMonth(false));
    console.log('getMonth(false)', getMonth(false));
    const lastDay = new Date(now.getFullYear(), getMonth(false), 0);
console.log(lastDay.getDate);
    return lastDay.getDate();
}
function getDateAf(p) {
    var date = new Date();
    date.setDate(date.getDate() + p);
    var year  = date.getFullYear();
    var month = date.getMonth() + 1;
    var day   = date.getDate();
    console.log('getDate', year, month, day, p);
    return String(year) + "年" + String(month) + "月" + String(day) + "日";
  }
function moveToTop(){
    const duration = 100;  // 移動速度（1秒で終了）
            const interval = 25;    // 0.025秒ごとに移動
            const step = -window.scrollY / Math.ceil(duration / interval); // 1回に移動する距離
            const timer = setInterval(() => {

                window.scrollBy(0, step);   // スクロール位置を移動

                if(window.scrollY <= 0) {

                    clearInterval(timer);

                }

            }, interval);
}
function array_key_exists(key, search){
    if( !search || (search.constructor !== Array && search.constructor !== Object) ){
        return false;
    }
    return key in search;
}

export default {
    getToday,
    getTodayNum,
    getMonth,
    getDay,
    getNowYm,
    getDayLast,
    moveToTop,
    array_key_exists,
    getDateAf
}
