<template>
    <main :class="displayClass">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'"></bs-page-title>
        <bs-msg-alert :alertClass="alertClass">{{this.alertMessage}}</bs-msg-alert>
        <div class="w100">
            <div class="row mb-5">
              <ul class="nav nav-tabs nav-fill">
                <li class="nav-item">
                  <a :class="tab_switch('data')" @click="tab_switch_action('data')" href="#">発注情報</a>
                </li>
                <li class="nav-item">
                  <a :class="tab_switch('set_date')" @click="tab_switch_action('set_date')" href="#">セット日</a>
                </li>
                <li class="nav-item">
                  <a :class="tab_switch('thread')" @click="tab_switch_action('thread')" href="#">詳細指示</a>
                </li>
              </ul>
            </div>
            <div class="row mb-3">
              <div :class="tabDisplay('set_date')">
                <h4>セット日</h4>
                <div class="row mb-2">
                  <div class="col-3">
                    <input type="date" v-model="set_date_date" class="form-control">
                  </div>
                  <div class="col-3">
                    <button type="button" class="btn btn-success" @click="updateSetDate()">
                      登録
                    </button>
                  </div>
                </div>
                <div class="accordion mb-3">
                  <div class="accordion-item" id="set_date_accordion_input_form">
                    <h2 class="accordion-header" id="set_date_head_frm">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#set_date_col_frm" aria-expanded="true" aria-controls="set_date_col_frm">
                        詳細を記入する
                      </button>
                    </h2>
                    <div id="set_date_col_frm" class="accordion-collapse collapse" aria-labelledby="set_date_head_frm" data-bs-parent="#set_date_accordion_input_form">
                      <div class="accordion-body row">
                        <div class="col">
                          <bs-canvas
                            v-model="set_date_draw"
                            :canvas-w="500"
                            :canvas-h="400"
                            :edit-sw="true"
                            :canvas-image-path="''"></bs-canvas>
                        </div>
                        <div class="col">
                          <textarea v-model="set_date_value" class="form-control" style="height:400px;"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h4>セット履歴</h4>
                <div class="accordion" v-if="Object.keys(set_date).length > 0" >
                  <div v-for="(date, index) in set_date" :key="index" class="accordion-item" id="set_date_accordion">
                    <h2 class="accordion-header" :id="'set_date_head_' + index">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#set_date_col_' + index" aria-expanded="true" :aria-controls="'set_date_col_' + index">
                        {{date.set_date_str}}
                      </button>
                    </h2>
                    <div :id="'set_date_col_' + index" class="accordion-collapse collapse" :aria-labelledby="'set_date_head_' + index" data-bs-parent="#set_date_accordion">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col" v-if="date.draw">
                          <bs-canvas
                              v-model="date.draw"
                              :canvas-w="500"
                              :canvas-h="400"
                              :edit-sw="false"
                              :canvas-image-path="''"></bs-canvas>
                          </div>
                          <div class="col">
                            {{ date.value }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col text-end">
                            <button type="button" class="btn btn-danger" @click="delSetDate(date.id)">
                              削除
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div :class="tabDisplay('data')">
                <h4>発注情報</h4>
                <table class="table">
                  <tbody>
                    <tr>
                      <th>発注主</th>
                      <td>{{order.orderer_label}}</td>
                    </tr>
                    <tr>
                      <th>発注先</th>
                      <td>{{order.contractor_label}}</td>
                    </tr>
                    <tr>
                      <th>発注日</th>
                      <td>{{order.order_date_str}}</td>
                    </tr>
                    <tr>
                      <th>希望日</th>
                      <td>
                        <input type="date" v-model="order.delivery_preferred_date_str" class="form-control form-control-sm">
                      </td>
                    </tr>
                    <tr>
                      <th>予定納期</th>
                      <td>
                        <input type="date" v-model="order.delivery_schedule_date_str" class="form-control form-control-sm">
                      </td>
                    </tr>
                    <tr>
                      <th>納品日</th>
                      <td>
                        <input type="date" v-model="order.delivery_date_str" class="form-control form-control-sm">
                      </td>
                    </tr>
                    <tr>
                      <th>発注書</th>
                      <td>{{order.template_label}}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="row" v-if="Object.keys(threads).length > 0" >
                  <div v-for="(thread, index) in threads" :key="index">
                    <div v-if="thread.order_head_flg == 1">
                      <p class="py-2 mb-2 border-bottom" v-if="thread.order_thread_type < 2000">
                        {{thread.body}}
                      </p>
                      <div v-else-if="thread.order_thread_type >= 2000">
                        <bs-canvas
                          v-model="thread.body"
                          :canvas-w="0"
                          :canvas-h="0"
                          :edit-sw="false"
                          :canvas-image-path="'/img/pen_input/' + thread.order_thread_type + '.png'"></bs-canvas>
                      </div>
                      <div v-if="thread.file_data && thread.order_thread_type < 2000" class="row">
                        <div class="col-6">
                          <div v-for="(file, index) in thread.file_data" :key="index" class="d-inline-block border p-2 me-2 mb-2">
                            <a :href="file.file_path" target="_blank" v-if="file.up_file_name"><i class="bi bi-file-earmark"></i> {{file.up_file_name}}</a>
                            <a :href="file.file_path" target="_blank" v-else><i class="bi bi-file-earmark"></i> ファイル{{(index + 1)}}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h4>発注内容</h4>
                <table class="table" v-if="order.template_id > 0 && Object.keys(order_detail).length > 0">
                  <tbody>
                    <tr v-for="(detail, index) in order_detail" :key="index">
                      <th style="width:15%;">{{detail.subject}}</th>
                      <td style="width:85%;">
                        <p v-if="detail.order_detail_type < 2000">
                        {{detail.value_label}}
                        </p>
                        <div class="w-100"
                            v-else-if="detail.order_detail_type >= 5000">
                         <bs-canvas
                            v-model="detail.value_label"
                            :canvas-w="0"
                            :canvas-h="0"
                            :edit-sw="false"
                            :canvas-image-path="detail.bg_inputs"></bs-canvas>
                        </div>
                        <div class="w-100"
                            v-else-if="detail.order_detail_type >= 2000">
                         <bs-canvas
                            v-model="detail.value_label"
                            :canvas-w="0"
                            :canvas-h="0"
                            :edit-sw="false"
                            :canvas-image-path="'/img/pen_input/' + detail.order_detail_type + '.png'"></bs-canvas>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row my-3 py-3">
                  <div class="col-md-12 mb-1">
                    <label for="sendmail_flg">
                    <input type="checkbox" id="sendmail_flg" v-model="sendmail_flg">
                    更新時メールを送信する
                    </label>
                  </div>
                  <div class="col-md-7">
                  <bs-select
                    id="OrderStep"
                    :options="order_step"
                    v-model="order.step"></bs-select>
                  </div>
                  <div class="col-md-5">
                  <bs-button :btnClass="'btn-warning'" @to_click_event="update">更新</bs-button>
                  </div>
                </div>
              </div>
              <div :class="tabDisplay('thread')">
                <h4>詳細指示</h4>
                <div class="mb-2">
                  <bs-select
                    id="OrderThreadType"
                    :options="order_thread_type"
                    v-model="thread_type"></bs-select>
                </div>
                <div v-if="thread_type >= 2000" class=" w-100">
                  <bs-canvas
                    v-model="thread_message"
                    :canvas-w="0"
                    :canvas-h="0"
                    :edit-sw="true"
                    :canvas-image-path="'/img/pen_input/' + thread_type + '.png'"></bs-canvas>

                </div>
                <div v-else-if="thread_type == 1000">
                  <div class="row">
                        <div class="col-4">
                          <input type="file" class="form-control" name="receipt_logo_image" @change="selectedFile()">
                        </div>
                        <div class="col-2">
                          <!-- <button type="button" class="btn btn-warning btn-sm">ファイル削除</button> -->
                        </div>
                      </div>
                </div>
                <div v-else>
                  <textarea v-model="thread_message" class="form-control" @dragenter="drag_enter" @dragleave="drag_leave" @drop.prevent="drag_drop()"></textarea>
                  <div class="row my-1">
                        <div class="col-4">
                          <input type="file" class="form-control" name="receipt_logo_image" @change="selectedFile()">
                        </div>
                        <div class="col-2">
                          <!-- <button type="button" class="btn btn-warning btn-sm">ファイル削除</button> -->
                        </div>
                      </div>
                </div>
                <div>
                  <div v-for="(file, index) in files" :key="index" class="d-inline-block border p-2 me-2 mb-2">
                    <a :href="file.file_path" target="_blank" v-if="file.up_file_name"><i class="bi bi-file-earmark"></i> {{file.up_file_name}}</a>
                    <a :href="file.file_path" target="_blank" v-else><i class="bi bi-file-earmark"></i> ファイル{{(index + 1)}}</a>
                  </div>
                </div>
                <div class="p-3 text-end mb-4">
                  <bs-button :btnClass="'btn-primary '" @to_click_event="updateThread">{{auth.label}}として投稿</bs-button>
                 <!-- <button class="btn btn-light btn-outline-secondary">投稿</button> -->
                </div>
                <div class="p-3 d-grid gap-2 d-block">
                  <button class="btn btn-light btn-outline-secondary" @click="loadThreadList">スレッドを更新する</button>
                 <!-- <button class="btn btn-light btn-outline-secondary">投稿</button> -->
                </div>
                <!-- {{thread_list}} -->
                <div class="row" v-if="Object.keys(threads).length > 0" >
                  <div v-for="(thread, index) in threads" :key="index">
                    <div :class="threadClass(thread.author_id, auth.userId)">
                      <p class="mb-0">
                        <span class="badge bg-secondary">{{thread.cre_date_str}}</span>
                      </p>
                      <p class="mb-0" v-if="thread.author_label">
                        <span class="badge bg-primary"><i class="bi bi-person"></i> {{thread.author_label}}</span>
                      </p>
                      <p class="py-2 mb-2 border-bottom" v-if="thread.order_thread_type < 2000">
                        {{thread.body}}
                      </p>
                      <div v-else-if="thread.order_thread_type >= 2000" class=" w-100">
                        <bs-canvas
                          v-model="thread.body"
                          :canvas-w="0"
                          :canvas-h="0"
                          :edit-sw="false"
                          :canvas-image-path="'/img/pen_input/' + thread.order_thread_type + '.png'"></bs-canvas>
                      </div>
                      <div v-if="thread.file_data && thread.order_thread_type < 2000" class="row">
                        <div class="col-6">
                          <div v-for="(file, index) in thread.file_data" :key="index" class="d-inline-block border p-2 me-2 mb-2">
                            <a :href="file.file_path" target="_blank" v-if="file.up_file_name"><i class="bi bi-file-earmark"></i> {{file.up_file_name}}</a>
                            <a :href="file.file_path" target="_blank" v-else><i class="bi bi-file-earmark"></i> ファイル{{(index + 1)}}</a>
                          </div>
                        </div>
                      </div>
                      <p v-else class="mb-0 text-secondary">
                        no file
                      </p>
                      <div class="border-top text-end py-1">
                        <button type="button" @click="update_thread_head_flg(index, 1)" v-if="thread.order_head_flg ==0" class="btn btn-warning btn-sm">発注情報に表示</button>
                        <button type="button" @click="update_thread_head_flg(index, 0)" v-else class="btn btn-warning btn-sm">発注情報から除外</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="alert alert-warning" v-else>
                  thread no data
                </div>
              </div>
            </div>
        </div>
    </main>
</template>
<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
import BsButton from '../../atoms/BsButton.vue'
import BsCanvas from '../../atoms/BsCanvas.vue'
import BsSelect from '../../atoms/BsSelect.vue'
import config from '../../../const'
import axios from 'axios'
// import '../../assets/bootstrap/css/sc.css';

export default {
  name: 'OrderDetailView',
  data: function () {
      return {
        'targetid': this.$route.params.id,
        'fromParentTitle': '発注',
        'fromParentBtn': '',
        'alertMessage': '',
        'alertClass': 'alert-info',
        'order': {},
        'order_detail': {},
        'add': true,
        'threads': {},
        'thread_message': '',
        'thread_type': 0,
        'uploadFile': {},
        'orderer_id': 0,
        'contractor_id': 0,
        'template_id': 0,
        'order_date': '',
        'form_info_sw': false,
        'author_id': 0,
        'subject': '',
        'set_date': {},
        set_date_date: '',
        set_date_value: '',
        set_date_draw: '',
        'body': '',
        sendmail_flg: false,
        auth:  this.$store.state.auth,
        files: [],
        up_files: [],
        // thread: {},
        // order_template_list: {},
        'tab_active': 'data',//or th ead
      }
  },
  components: {
      BsPageTitle,
      BsMsgAlert,
      BsButton,
      BsSelect,
      BsCanvas,
  },
  methods: {
    delSetDate(target){
        if(confirm('削除しますか？')){
            const data = !Number.isNaN(target)
            ? {...this.$store.getters.getBasicById(target, 'order_set_date')}
            : {};
            // console.log('ret ticket data' ,ticket);
            data['stat'] = 9;
            return this.$store.dispatch('updateBasic', {data: data , target: 'order_set_date'})
                .then(() => {
                    this.alertMessage = '削除しました';
                    this.alertClass = 'alert-success';
                    // Common.moveToTop();
                })
                .catch((err) => {
                    // Promise.reject(err)
                    console.log(err);
                    this.alertMessage = '削除できませんでした';
                    this.alertClass = 'alert-danger';
                    // Common.moveToTop();
                })
                .finally(() => {
                    this.loadOrderSetDate();
                });
        }
    },
    updateSetDate(){
      const post = {};
      post['value'] = this.set_date_value;
      post['draw'] = this.set_date_draw;
      post['set_date'] = this.set_date_date;
      post['order_id'] = this.targetid;
      // this.ticket['lines'] = this.ticket_line_data
      return this.$store.dispatch('updateBasic', {data: post , target: 'order_set_date'})
        .then(() => {
            this.alertMessage = this.btnLabel + 'しました';
            this.alertClass = 'alert-success';
            this.loadOrderSetDate();
            this.set_date_value = '';
            this.set_date_date = '';
            this.set_date_draw = '';
        })
        .catch(err => Promise.reject(err))
    },
    tab_switch_action(tab) {
      this.tab_active = tab;
    },
    drag_leave(){
      console.log('drag_leave');
    },
    drag_drop(){
      this.up_files = [...event.dataTransfer.files];
      if(this.up_files.length > 0){
        // console.log('params', params);
        this.files = [];
        this.up_files.forEach(file => {
          this.threadFileUp(file);
        });

      }
      console.log('drag_drop', this.files);
    },
    drag_enter(){
      console.log('drag_enter');

    },
    selectedFile(){
      const files = event.target.files;
      // console.log('selectedFile!', typeof(files), files, indexx);
      // event.preventDefault();
      // let files = e.target.files;
      console.log('selectedFile', typeof(files), files);
        this.files = [];
      if(files){
        this.threadFileUp(files[0]);
      }
    },
    threadFileUp(file){
        const header = {
          [config.BASE_API_HEADER_KEY]: this.$store.state.auth.token
        };
          const form = new FormData();
          form.append('file', file);
          axios.post(
            config.BASE_API_URL + '/upload/thread',
            form,
            {
              headers: header
            }
          )
          .then(res => {
            console.log(res);
            if(res && res.status == 200){
              this.files.push(res.data.body);
            }
          })
          .catch(error => {
            console.log(error);
          });
    },


    contractorChange: function(){
      // this.template_id = 0;
    },
    // selectedFile: function(e) {
    //   e.preventDefault();
    //   let files = e.target.files;
    //   console.log('selectedFile', typeof(files),  files);
    //   if(files){
    //   this.uploadFile = files[0];
    //   }
    // },
    updateThread(){
      const post = {};
      post['body'] = this.thread_message;
      post['order_id'] = this.targetid;
      post['order_thread_type'] = this.thread_type;
      post['author_id'] = this.auth.userId;
      post['files'] = this.files;
      // this.ticket['lines'] = this.ticket_line_data
      return this.$store.dispatch('updateBasic', {data: post , target: 'thread'})
        .then(() => {
            this.alertMessage = this.btnLabel + 'しました';
            this.alertClass = 'alert-success';
            this.loadThreadList();
            this.thread_message = '';
            this.thread_type = 0;
            this.files = {};
        })
        .catch(err => Promise.reject(err))
    },
    loadOrderSetDate() {
      this.loadOrderSetDateS()
      .then((res) =>{
        console.log('loadOrderSetDateS 122', res);
      })
      .catch((error) => {
        console.log('loadOrderSetDateS error', error);
        this.$store.state.apidata.order_set_date = {};
      })
      .finally(() => {
        console.log('loadOrderSetDateS finally 122', this.$store.state.apidata.order_set_date);
        // const tmp = {};
        // if(this.)
        this.set_date = this.$store.state.apidata.order_set_date.reverse();
      });
    },
    loadOrderSetDateS() {
      const post = {};
      post['stat'] = '1';
      post['order_id'] = this.targetid;
      return this.$store.dispatch('fetchBasicLists', {data: post, target: 'order_set_date'})
                  .catch(err => Promise.reject(err))
                  .then(() => {
                      // console.log('loard complete couse_list', res);
                      console.log('this set date then', this.$store.state.apidata.order_set_date);
                      // this.$router.push({ path: '/login' })
                  })
                  .finally(() => {
                      console.log('set date finally', this.$store.state.apidata.order_set_date);
                  })
    },
    loadThreadList() {
      this.loadThreadListS()
      .then((res) =>{
        console.log('loadthreadlists 122', res);
      })
      .catch((error) => {
        console.log('loadthreadlists error', error);
        this.$store.state.apidata.thread = {};
      })
      .finally(() => {
        console.log('loadthreadlists finally 122', this.$store.state.apidata.thread);
        this.threads = this.$store.state.apidata.thread.reverse();
      });
    },
    loadThreadListS() {
      const post = {};
      post['order_id'] = this.targetid;
      return this.$store.dispatch('fetchBasicLists', {data: post, target: 'thread'})
                  .catch(err => Promise.reject(err))
                  .then(() => {
                      // console.log('loard complete couse_list', res);
                      console.log('this', this.$store.state.apidata.thread);
                      // this.$router.push({ path: '/login' })
                  })
                  .finally(() => {
                      console.log('course finally', this.$store.state.apidata.thread);
                  })
    },
    loadOrdererList() {
        return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'orderer', targetF: 'select'})
                    .catch(err => Promise.reject(err))
                    .then(() => {
                        // console.log('loard complete couse_list', res);
                        console.log('this', this.$store.state.apidata.orderer);
                        // this.$router.push({ path: '/login' })
                    })
                    .finally(() => {
                        console.log('course finally', this.$store.state.apidata.orderer);
                    })
    },
    // 紐付きの発注先を取得
    loadContractorList() {
        return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'contractor', targetF: 'select'})
                    .catch(err => Promise.reject(err))
                    .then(() => {
                        // console.log('loard complete couse_list', res);
                        console.log('this', this.$store.state.apidata.contractor);
                        // this.$router.push({ path: '/login' })
                    })
                    .finally(() => {
                        console.log('course finally', this.$store.state.apidata.contractor);
                    })
    },
    // 紐付きの発注先を取得
    loadTemplateList() {
        return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'template', targetF: 'lists'})
                    .catch(err => Promise.reject(err))
                    .then(() => {
                        // console.log('loard complete couse_list', res);
                        console.log('this', this.$store.state.apidata.template);
                        // this.$router.push({ path: '/login' })
                    })
                    .finally(() => {
                        console.log('template finally', this.$store.state.apidata.template);
                    })
    },
    loadGetList (target_name) {
        // console.log('tag');
        return this.$store.dispatch('fetchBasicLists', {data: {}, target: target_name})
                    .catch(err => Promise.reject(err))
                    .then((res) => {
                      console.log('loadGetList', target_name, res);
                    })
                    .finally(() => {})
    },
    //
    loadOrderThreadTypeList() {
        return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'order_thread_type', targetF: 'lists'})
                    .catch(err => Promise.reject(err))
                    .then(() => {
                        // console.log('loard complete couse_list', res);
                        console.log('this', this.$store.state.apidata.template);
                        // this.$router.push({ path: '/login' })
                    })
                    .finally(() => {
                        console.log('template finally', this.$store.state.apidata.template);
                    })
    },
    update_thread_head_flg(thread_index, flg){
      const post = {
        'id': this.threads[thread_index].id,
        'order_head_flg': flg,
      }
      return this.$store.dispatch('updateBasic', {data: post , target: 'thread'})
        .then(() => {
            this.alertMessage = this.btnLabel + 'しました';
            this.alertClass = 'alert-success';
            this.loadThreadList();
            this.thread_message = '';
            this.thread_type = 0;
            this.files = {};
        })
        .catch(err => Promise.reject(err))

    },
    update(){
      console.log('component methods', this.order_detail);

      const post = {
        'id': this.targetid,
        'orderer_id': this.order.orderer_id,
        'contractor_id': this.order.contractor_id,
        'template_id': this.order.template_id,
        'step': this.order.step,
        'order_date': this.order.order_date,
        // 'delivery_preferred_date': this.delivery_preferred_date,
        // 'delivery_schedule_date': this.delivery_schedule_date,
        // 'delivery_date': this.delivery_date,
        'subject': this.subject,
        'body': this.body,
        'author_id': this.author_id,
        'order_detail': this.order_detail,
      }
      if(this.sendmail_flg){
        post['sendmail_flg'] = 1;
      }else{
        post['sendmail_flg'] = 0;
      }
      if(this.step){
        post['step'] = this.step;
      }
      if(this.order.delivery_preferred_date_str){
        post['delivery_preferred_date'] = this.order.delivery_preferred_date_str;
      }
      if(this.order.delivery_schedule_date_str){
        post['delivery_schedule_date'] = this.order.delivery_schedule_date_str;
      }
      if(this.order.delivery_date_str){
        post['delivery_date'] = this.order.delivery_date_str;
      }
      // this.device.receipt_logo_image = this.uploadFile;
      // console.log('upload upload receipt', this.uploadFile, this.device.receipt_logo_image);
      return this.$store.dispatch('updateBasic', {data: post, target: 'order'})
        .then(() => {
          // this.back()
          this.alertMessage = this.btnLabel + 'しました';
          this.alertClass = 'alert-success';
        })
        .catch(
          () => {
          err => Promise.reject(err);
            this.alertMessage = this.btnLabel + 'できませんでした';
            this.alertClass = 'alert-danger';
          }
        )
    }
  },
  computed: {
    ...mapState({
      set_date(state){

        return state.apidata.order_set_date;
      },
      threads(state){
        // const tmp = {};
        // let tmp = {};
        // if(Object.keys(state.apidata.thread).length > 0 && this.targetid > 0 && typeof state.apidata.thread[this.targetid] == 'object'){
        //   tmp = state.apidata.thread[this.targetid];
        // }
        console.log(state.apidata.thread, 'thread122');
        return state.apidata.thread;
      },
      // threads: state => state.apidata.thread,
      order_step: state => state.apidata.order_step,
      orderer_list: state => state.apidata.orderer,
      order_thread_type: state => state.apidata.order_thread_type,
      author_id: state => state.auth.userId,
      contractor_list: state => state.apidata.contractor,
      order_template(state) {
        if(this.contractor_id > 0 && Object.keys(state.apidata.template).length > 0){
          this.template_id = 0;
            const val = Object.keys(state.apidata.template).reduce((tmp, tmp_id) => {
              console.log('reduce', tmp, tmp_id);
              if(state.apidata.template[tmp_id].contractor_id == this.contractor_id){
              console.log('reduce if ', state.apidata.template[tmp_id], {[tmp_id]: state.apidata.template[tmp_id]});
                tmp[tmp_id] = state.apidata.template[tmp_id];
                tmp[tmp_id]['label'] += '[' + tmp_id + ']';
             }
              return tmp;
            }, {})
            ;
            console.log('reduce val ', val);
            return val
        }else{
          return false;
        }
      },
      thread_head(state) {
        if(Object.keys(state.apidata.threads).length > 0){
          const head_thread = {};
          state.apidata.threads.forEach(thread => {
            if(thread.order_head_flg == 1){
              head_thread[thread.id] = thread;
            }
          });
          return head_thread;
        }else{
          return false;
        }
      },
    // order_template: state => state.apidata.template_id,
    }),
    // thread_order_chk: function () {
    //   if(Object.keys(this.threads).length > 0){
    //     this.threads.forEach(thread => {
    //       console.log(thread);
    //     });
    //   }
    // },
    tabDisplay: function () {
      const self_tab_active = this.tab_active;
      return function (tab) {
        let tabClass = 'd-none ';
        if(self_tab_active == tab){
          tabClass = 'd-block';
        }
        return tabClass;
      }

    },
    threadClass: function () {
      return function (author_id, userId){
        const t_class = 'col-10 p-2 mb-3 border rounded';
        if(author_id == userId){
          return t_class + ' offset-2 ';
        }
        else{
          return t_class + ' border-info ';
        }
      }
    },
    tab_switch: function() {
      const self_tab_active = this.tab_active;
      return function (tab) {
        let tabClass = 'nav-link ';
        console.log('tab_switch', self_tab_active);
        if(self_tab_active == tab){
          tabClass += 'active';
        }
        return tabClass;
      }
    },
    thread_list: function(){
       console.log(this.threads, 'this.threads 122');
      return this.threads;
    },
    head_thread_list: function() {
      if(this.contractor_id > 0){
        return this.head_thread;
      }else{
        return false;
      }
    },
    order_template_list: function() {
      if(this.contractor_id > 0){
        return this.order_template;
      }else{
        return false;
      }
    },
    btnLabel() {
        if(this.add){
            return '発注詳細';
        }
        else{
            return '更新';
        }
    },
  },
  created () {
    // this.loadCourse();
    // this.loadDepartment();
    this.loadGetList('order_step');
    this.loadContractorList();
    this.loadOrdererList();
    this.loadTemplateList();
    this.loadOrderThreadTypeList();
    if(this.targetid == 'add'){
      console.log('add');
    }else{
    this.loadOrderSetDate();
      this.loadThreadList();
      // .then((res) =>{
      //   console.log('loadthreadlists 122', res);
      // })
      // .finally(() => {
      //   console.log('loadthreadlists finally 122', this.$store.state.apidata.thread);
      //   this.threads = this.$store.state.apidata.thread;
      // });

      const ret = !Number.isNaN(this.targetid)
        ? {...this.$store.getters.getBasicById(this.targetid, 'order')}
        : {};
      if(Object.keys(ret).length > 0){
        this.add = false;
      }
      this.order = ret;
      console.log('create order ', ret, this.order);
      this.order_detail = ret.details;

    console.log('order thread get ', this.threads);

    }
  },
  mounted: function(){
      // console.log(this.$route.path);
      // console.log('strage', this.$store.state.article);
      // console.log('this', ...this.$store.getters.getArticleById(1));
      this.fromParentTitle = '発注詳細';
      this.alertMessage = this.btnLabel + 'できます';
      // console.log('tags_list', this.tags_list);
  },

  props: {
    // tab_active: {
    //     type: String,
    //     default: 'data'
    //   },
      displayClass: {
          type: String,
          default: 'col-md-9 ms-sm-auto col-lg-10 px-md-4'
      },

    },

}
</script>
