<template>
  <div class="row mb-3">
    <div v-for="(option, index) in options" :key="index" class="d-sm-block col-sm-4 d-md-block col-md-4 mb-3">
      <bs-button :btnClass="btnClasses(option.id)" v-if="true" @to_click_event="handleClick(option.id)">{{option.label}}</bs-button>
    </div>
    <div>{{modelValue}}</div>
  </div>
</template>

<script>
import BsButton from './BsButton.vue';

export default {
  name: "BsSelectButtonMulti",
  components: {
      BsButton
  },
  props: {
    btnClass: {
        type: String,
        default: 'btn-light btn-outline-secondary'
    },
    modelValue:{type:Array},
    value: { type: Object, required: true },
    options: { type: Object, required: true },
    name: { type: String, required: true },
  },
  computed: {
    btnClasses: function() {
      // self = this;
      const val = this.modelValue;
      return function (btnVal){
        let cls = '';
        console.log('btnClasses', btnVal, val);
        if(val != undefined && val.indexOf(btnVal) >= 0){
          cls = 'btn-primary';
        }else{
          cls += 'btn-light btn-outline-secondary';
        }
        return [`btn btn-lg d-block ${cls}`];
      }
    }
    //     console.log(this.modelValue);

    //     if(this.modelValue == btnVal){
    //         cls =  'btn-primary';
    //     }else{
    //         cls += 'btn-light btn-outline-secondary';
    //     }
    //     return [`btn ${cls}`]
    // }
  },
  methods: {
    handleClick: function(targetid) {
        const tmp = (this.modelValue)? this.modelValue: [];
        // tmp.push(this.modelValue);
        console.log(tmp, targetid, this.modelValue);
        if(tmp.indexOf(targetid) < 0){
          tmp.push(targetid);
        }else if (tmp.indexOf(targetid) >= 0){
          tmp.splice(tmp.indexOf(targetid), 1);
        }
        console.log('after push', tmp, targetid, this.modelValue);

				this.$emit('update:modelValue',tmp);
				this.$emit('parentFunc',tmp);
    }
  }
};
</script>
