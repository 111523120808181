<template>
    <nav id="sidebarMenu" :class="displayStyle + ' bg-light sidebar collapse'" >
        <div class="position-sticky pt-3">
            <ul class="nav flex-column">
                <li class="nav-item">
                    <router-link to="/" class="nav-link" aria-current="page" href="#">
                        <vue-feather type="home" />
                        ダッシュボード
                    </router-link>
                </li>
                <li class="nav-item" v-if="check_co_data">
                    <router-link to="/order/add" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-receipt"></i>
                        発注
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/order/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-list"></i>
                        受発注一覧
                    </router-link>
                </li>
            </ul>
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>設定</span>
                <i class="bi bi-gear"></i>
            </h6>
            <ul class="nav flex-column">
                <li class="nav-item">
                    <router-link to="/co_data/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-building"></i>
                        所属医院/技工所
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/relation/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-star"></i>
                        関係会社
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/template/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-file-earmark-text"></i>
                        テンプレート
                    </router-link>
                </li>
                <li class="nav-item d-none">
                    <router-link to="/template/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-calendar-x"></i>
                        休業日設定
                    </router-link>
                </li>
            </ul>
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>工程</span>
                <i class="bi bi-bar-chart-steps"></i>
            </h6>
            <ul class="nav flex-column">
                <li class="nav-item">
                    <router-link to="/order_step/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-building"></i>
                        工程一覧
                    </router-link>
                </li>
            </ul>
            <ul class="nav flex-column ">
                <li class="nav-item " >
                    <router-link to="/logout" class="nav-link" aria-current="page" href="#">
                        <i class="fa bi-box-arrow-right"></i>
                        ログアウト
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>
<style scoped>
    #sidebarMenu {
        position: relative !important;
        padding-top: 0 !important;
    }
</style>
<script>
import { mapState } from 'vuex'
import 'feather';
import 'vue-feather'
import VueFeather from 'vue-feather';
// import { ActivityIcon } from "vue-feather-icons";
export default {
    name: 'BsSideMenuView',
    components: {
        VueFeather
    },
    data: function () {
        return {
            auth:  this.$store.state.auth,
            // displaySw: false
        }
    },
    props: {
        // article: {
        //   type: Object,
        //   required: true,
        //   default: () => {}
        // },
        displaySw: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        ...mapState({
            co_data: state => state.apidata.co_data,
        }),
        check_co_data(){
            console.log('this.auth', this.auth);
            if(this.auth.co_check > 0){
                return true;
            }
            else{
                return false;
            }
            // console.log('side menu ', this.co_data);
            // let check = false;
            // Object.keys(this.co_data).forEach((index) => {
            //         console.log('chkchke', index, this.co_data[index]);
            //     if(this.co_data[index].co_type == 100){
            //         check = true;
            //     }
            // });
            // return check;
        },
        displayStyle () { // validを使ってログイン処理の可否、progressは後述

            if(this.displaySw){
                return 'col-md-3 col-lg-2 d-block d-md-block';
            }else{
                return 'd-none';
            }
        }
    },
}
</script>
