import Template from '../components/templates/BsTemplateView.vue'
import Login from '../components/templates/BsLoginView.vue'
import Logout from '../components/templates/BsLogoutView.vue'
import Error from '../components/templates/BsErrorView.vue'
import PagesList from '../components/templates/BsDataListView.vue'
import DashBoard from '../components/templates/BsDashBoardView.vue'
import OrderList from '../components/templates/Order/ListView.vue'
import OrderAdd from '../components/templates/Order/AddView.vue'
import OrderData from '../components/templates/Order/DataView.vue'
// import CategoryList from '../components/templates/Category/ListView.vue'
// import CategoryData from '../components/templates/Category/DataView.vue'
// import TypeList from '../components/templates/Type/ListView.vue'
// import TypeData from '../components/templates/Type/DataView.vue'
// import PositionData from '../components/templates/Type/PositionView.vue'
// import TagList from '../components/templates/Tag/ListView.vue'
// import TagData from '../components/templates/Tag/DataView.vue'
import RelationList from '../components/templates/Relation/ListView.vue'
import RelationData from '../components/templates/Relation/DataView.vue'
import CoList from '../components/templates/CoData/ListView.vue'
import CoData from '../components/templates/CoData/DataView.vue'
import TemplateList from '../components/templates/Template/ListView.vue'
import TemplateData from '../components/templates/Template/DataView.vue'
import OrderStepList from '../components/templates/OrderStep/ListView.vue'
import OrderStepData from '../components/templates/OrderStep/DataView.vue'

export default [
    {
        path: '/',
        name: 'base',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: DashBoard,
            },
            {
                path: '/pages/list',
                name: 'pages_list',
                component: PagesList,
            }
        ]
    },
    {
        path: '/co_data',
        name: 'co_data',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/co_data/:id',
                name: 'co_detail',
                component: CoData,
            },
            {
                path: '/co_data/list',
                name: 'co_list',
                component: CoList,
            }
        ]
    },
    {
        path: '/template',
        name: 'relation',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/relation/:id',
                name: 'relation_detail',
                component: RelationData,
            },
            {
                path: '/relation/list',
                name: 'relation_list',
                component: RelationList,
            }
        ]
    },
    {
        path: '/template',
        name: 'template',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/template/:id',
                name: 'template_detail',
                component: TemplateData,
            },
            {
                path: '/template/list',
                name: 'template_list',
                component: TemplateList,
            }
        ]
    },
    {
        path: '/order_step',
        name: 'order_step',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/order_step/:id',
                name: 'order_step_detail',
                component: OrderStepData,
            },
            {
                path: '/order_step/list',
                name: 'order_step_list',
                component: OrderStepList,
            }
        ]
    },
    {
        path: '/order',
        name: 'order',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/order/:id',
                name: 'order_detail',
                component: OrderData,
            },
            {
                path: '/order/add',
                name: 'order_add',
                component: OrderAdd,
            },
            {
                path: '/order/list',
                name: 'order_list',
                component: OrderList,
            }
        ]
    },
    // {
    //     path: '/sales',
    //     name: 'sales',
    //     component: Template,
    //     meta: {
    //         requiresAuth: true
    //     },
    //     children: [
    //         {
    //             path: '/sales/:id',
    //             name: 'sales_detail',
    //             component: SalesData,
    //         },
    //         {
    //             path: '/sales/list',
    //             name: 'sales_list',
    //             component: SalesList,
    //         },
    //         // {
    //         //     path: '/sales/add',
    //         //     name: 'sales_detail',
    //         //     component: SalesData,
    //         // },
    //         {
    //             path: '/sales/report',
    //             name: 'sales_report',
    //             component: SalesReport,
    //         },
    //     ]
    // },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '/data/list',
        name: 'DataList',
        component: Template,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/error',
        name: 'error',
        component: Error
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: './error'
    }
]
