import * as types from './mutation-types'
import { Article, Auth } from '../api'
import Basic from '../api/basic'

export default {
  login: ({ commit }, authInfo) => {
    return Auth.login(authInfo)
      .then(({ token, userId, label/* ,body */, co_check }) => {
        localStorage.setItem('token', token)
        commit(types.AUTH_LOGIN, { token, userId, label, co_check });
        console.log('logincheck',token, userId, label, co_check);
      })
      .catch(err => { throw err })
  },
  logout: ({ commit, state }) => {
    return Auth.logout(state.auth.token)
      .then(() => {
        localStorage.removeItem('token')
        commit(types.AUTH_LOGOUT, { token: null, userId: null })
      })
      .catch(err => { throw err })
  },

  fetchBasicLists: ({ commit, state }, data, target, targetF) => {
    console.log('commit fetch basic lists:', data, target, targetF);
    return Basic.fetch(state.auth.token, data, target, targetF)
      .then(({basic, target, pager}) => {
        console.log('actions fetch basic', basic, target, pager);
        const payload = [
          basic,
          target
        ];
        commit(types.FETCH_ALL_BASICLIST, payload);
        if(pager){
          commit(types.FETCH_ALL_PAGER, pager);
        }
      })
      .catch(err => { throw err })
  },
  updateBasic: ({ commit, state }, data, target) => {
    // console.log('actions.js 開始', data);
    return new Promise((resolve, reject) => {
      Basic.update(state.auth.token, data, target)
      .then((res) => {
        console.log('updateBasic AAAA', res);
        commit(types.UPDATE_BASIC, data, target);
        resolve(res);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      })
      // .catch(err => { throw err })
    })
    // return Basic.update(state.auth.token, data, target)
    //   .then((res) => {
    //     console.log('updateBasic AAAA', res);
    //     commit(types.UPDATE_BASIC, data, target);
    //     // resolve(res);
    //   })
    //   .catch(err => { throw err })
  },

  fetchArticleLists: ({ commit, state }, data) => {
    return Article.fetch(state.auth.token, data)
      .then(({ article, pager }) => {
        console.log('actions fetch article', article, pager);
        commit(types.FETCH_ALL_ARTICLELIST, article);
        commit(types.FETCH_ALL_PAGER, pager);
        // commit(types.FETCH_ALL_TAGLIST, tags);
      })
      .catch(err => { throw err })
  },
  updateArticle: ({ commit, state }, data) => {
    // console.log('actions.js 開始', data);
    return Article.update(state.auth.token, data)
      .then(() => {
        commit(types.UPDATE_ARTICLE, data)
      })
      .catch(err => { throw err })
  },


}
