<template>
  <div :style="btnWrapStyle">
  <button
    :class="classes"
    :disabled="disabled"
    type="button"
    @click="handleClick"
  >
    <slot />
  </button>
  </div>
</template>

<script>
export default {
  name: 'BsButton',

  props: {
    type: {
      type: String,
      default: 'button'
    },
    btnClass: {
      type: String,
      default: 'btn-light'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    btnWrapStyle: {
      type: String,
      default: ''
    }
  },

  computed: {
    // `type`に応じてクラスを動的に生成する
    classes () {
      // const cls = this.type === 'text' ? ('-' + this.type) : ''
      // const clses = this.classes.split(',');
      let cls = '';
      console.log(this.btnClass);

      if(this.btnClass){
          cls = this.btnClass;
      }else{
        cls += 'btn-light btn-outline-secondary';
      }
      return [`btn ${cls}`]
    }
  },

  methods: {
    // `click`イベントを発行
    handleClick (ev) {
      this.$emit('to_click_event', ev)
    }
  }
}
</script>
