<template>
    <div id="home">
        <BsHeaderView @toggle="menuToggle"></BsHeaderView>
          <div class="container-fluid">
              <div class="row row-eq-height">
                  <bs-side-menu-view :display-sw="menuDisplay"></bs-side-menu-view>
                  <router-view :display-class="mainDisplayClass"></router-view>
              </div>
          </div>
        <BsFooterView />
    </div>
</template>
<style scoped>
  .row-eq-height {
    display: flex;
    flex-wrap: wrap;
  }
  .table tbody tr td {
    vertical-align: middle !important;
  }
</style>
<style>
  .table tbody tr td {
    vertical-align: middle !important;
  }
</style>
<script>
// import Vue from 'vue'
import BsHeaderView from '../organisms/BsHeaderView.vue'
import BsFooterView from '../organisms/BsFooterView.vue'
import BsSideMenuView from '../organisms/BsSideMenuView.vue'
import { mapState } from 'vuex'
export default {
  name: 'home',
  data: function(){
      return {
          test: 'tetestte',
          menuDisplay: true,
        //   count: this.$store.state.count
      }
  },
  computed:{
    ...mapState(['count']),
    mainDisplayClass() {
      if(this.menuDisplay){
        return 'col-md-9 ms-sm-auto col-lg-10 px-md-4';
      }else{
        return 'col-md-12 ms-sm-auto col-lg-12 px-md-4';
      }
    },
  },
  components: {
    BsHeaderView,
    BsFooterView,
    BsSideMenuView,
  },
  methods:{
    menuOpen(){},
    menuToggle(){
      console.log('toggle !!');
      if(this.menuDisplay){
        this.menuDisplay = false;
      }else{
        this.menuDisplay = true;
      }
    }
    // plus(){
    //     console.log('Home +');
    //     this.$store.commit('Splus')
    // },
    // minus(){
    //     this.$store.commit('Sminus')
    // },
  },
//   computed: {
//     count(){
//       return 0
//     },
//     test(){
//       return 0
//     },
//   },
//   watch: {
//     test: function(newVal, oldVal){
//       console.log(oldVal + '=>' + newVal);
//     }
//   },
//   methods:{
//     onincrement(){
//       this.$store.commit('incrementCnt')
//     },
//     ondecrement(){
//       this.$store.commit('decrementCnt')
//     },
//     actionDo(){
//       this.$store.commit('Do')
//     },
//     actionNotDo(){
//       this.$store.commit('Not')
//     }
//   }
}
</script>

<style scoped>
    @import "../../assets/bootstrap/css/dashboard.css";
</style>
