<template>
  <div class="row w-100">
    <div id="canvas-area" class="w-100" ref="canvasParent">
      <canvas id="myCanvas"
        :width="getCanvasW(canvasW)"
        :height="getCanvasH(canvasH)"
        :style="getCanvasImage(canvasImagePath)"
        ref="canvas"
        @touchstart="dragStart"
        @touchmove="draw"
        @touchend="dragEnd"
        @touchcancel="dragEnd"
        @mousedown="dragStart"
        @mouseup="dragEnd"
        @mouseout="dragEnd"
        @mousemove="draw" v-if="editSw"></canvas>
      <canvas id="myCanvas"
        :width="getCanvasW(canvasW)"
        :height="getCanvasH(canvasH)"
        :style="getCanvasImage(canvasImagePath)"
        ref="canvas"
        @mousemove="draw" v-else></canvas>
    </div>
    <div class="row mb-3" v-if="editSw">
        <div class="col-sm-4">
          <bs-button :btnWrapStyle="'display:inline-block;margin-right:10px;'" :btnClass="btnClasses('penBlack')" v-if="true" @to_click_event="penMode('penBlack')">黒</bs-button>
          <bs-button :btnWrapStyle="'display:inline-block;margin-right:10px;'" :btnClass="btnClasses('penBlue')" v-if="true" @to_click_event="penMode('penBlue')">青</bs-button>
          <bs-button :btnWrapStyle="'display:inline-block;'" :btnClass="btnClasses('penRed')" v-if="true" @to_click_event="penMode('penRed')">赤</bs-button>
        </div>
        <div class="col-sm-4">
          <bs-button :btnWrapStyle="'display:inline-block'" :btnClass="''" v-if="true" @to_click_event="memory('')">
            <i class="bi bi-arrow-90deg-left"></i>
          </bs-button>
          <bs-button :btnWrapStyle="'display:inline-block'" :btnClass="''" v-if="true" @to_click_event="memory('')">
            <i class="bi bi-arrow-90deg-right"></i>
          </bs-button>
        </div>
        <div class="col-sm-4 text-right">
          <bs-button :btnWrapStyle="'display:inline-block'" :btnClass="btnClasses('eraser')" v-if="true" @to_click_event="penMode('eraser')">
            <i class="bi bi-eraser-fill"></i>
          </bs-button>
        </div>
    </div>
  </div>
</template>

<style scoped>
  #myCanvas {
   border: 1px solid #000000;
  }
  </style>

<script>
import BsButton from './BsButton.vue';

export default {
  name: "BsCanvas",
  components: {
      BsButton
  },
  data() {
    return {
      canvasMode: 'penBlack',
      canvas: null,
      canvasX: 0,
      canvasY: 0,
      pointX: 0,
      pointY: 0,
      context: null,
      isDrag: false,
      canvasParentW: 0,
      canvasParentH: 0,
    };
  },
  props: {
    editSw:{
      type:Boolean,
      default: false
    },
    btnClass: {
        type: String,
        default: 'btn-light btn-outline-secondary'
    },
    modelValue:{type:String},
    canvasW: {type:Number, default: 500},
    canvasH: {type:Number, default: 500},
    canvasImagePath: {
      type: String,
      default: ''
    }
    // modelValue:{type:Array},
    // value: { type: Object, required: true },
    // options: { type: Object, required: true },
    // name: { type: String, required: true },
  },
  mounted: function(){

    this.canvasParentW = this.$refs.canvasParent.clientWidth;
    this.canvasParentH = this.$refs.canvasParent.clientHeight;

    console.log('mycanvas mounted', this.$refs);
    this.canvas = this.$refs.canvas;
    this.canvasX = parseInt(this.canvas.getBoundingClientRect().left);
    this.canvasY = parseInt(this.canvas.getBoundingClientRect().top);
    console.log('mycanvas next', this.canvasX, this.canvasY, this.canvas);
    // this.penBlack();
    this.context = this.canvas.getContext('2d');

    if(!this.editSw){
      console.log('editsw', this.modelValue);
      var img = new Image();
      img.src = this.modelValue;
      img.onload = () => {
        this.context.drawImage(img, 0, 0, this.getCanvasW(this.canvasW), this.getCanvasH(this.canvasH));
      }
    }

  },
  computed: {
    getCanvasImage: function(){
      return (path) => {
        if(path && path != ''){
          return 'background:url(' + path + ') no-repeat center center;background-size:contain;';
        }
        else{
          return '';
        }
      }
    },
    getCanvasW: function() {
      return (w) => {
        if(!isNaN(w) && w > 0){
          return w;
        }
        else{
          return this.canvasParentW;
        }
      }
    },
    getCanvasH: function() {
      return (h) => {
        if(!isNaN(h) && h > 0){
          return h;
        }
        else if(this.canvasParentH < 100){
          return this.canvasParentW * 0.55;
        }
        else{
          return this.canvasParentH;
        }
      }
    },
    btnClasses: function() {
      // self = this;
      return function (btnVal){
        let cls = '';

        switch (btnVal) {
          case 'penBlue':
            cls = 'btn-primary';
            break;
          case 'penRed':
            cls = 'btn-danger';
            break;
          case 'eraser':
            cls = 'btn-light'
            break;
          case 'penBlack':
          default:
            cls = 'btn-dark';
            break;
        }
        if(this.canvasMode == btnVal){
          return [`btn ${cls} d-block`];
        }else{
          return [`btn btn-light d-block btn-outline-secondary`];
        }
      }
    }
  },
  methods: {
    toData: function(){
      const base64 = this.canvas.toDataURL('image/png');
				this.$emit('update:modelValue',base64);
      // this.modelValue = base64;
      // console.log('base64', base64);
    },
    // 消しゴムモード
    eraser: function() {
      // カーソル変更
      this.canvasMode = 'eraser'

      // 描画設定
      this.context.lineCap = 'square';
      this.context.lineJoin = 'square';
      this.context.lineWidth = 30;
      // this.context.strokeStyle = '#FFFFFF';
    },
    penMode: function(colorMode){
      this.canvasMode = colorMode;
      if(colorMode == 'eraser'){
        this.context.globalCompositeOperation = 'destination-out';
        this.context.lineCap = 'square';
        this.context.lineJoin = 'square';
        this.context.lineWidth = 30;
        // this.context.strokeStyle = '#FFFFFF';
      }
      else{
        this.context.globalCompositeOperation = 'source-over';
        this.context.lineCap = 'round';
        this.context.lineJoin = 'round';
        this.context.lineWidth = 5;
        switch (colorMode) {
          case 'penBlue':
            this.context.strokeStyle = '#0000FF';
            break;
          case 'penRed':
            this.context.strokeStyle = '#FF0000';
            break;
          case 'penBlack':
          default:
            this.context.strokeStyle = '#000000';
            break;
        }
      }

    },
    // // ペンモード（黒）
    // penBlack: function(){
    //   // カーソル変更
    //   this.canvasMode = 'penBlack'

    //   // 描画設定
    //   this.context.lineCap = 'round';
    //   this.context.lineJoin = 'round';
    //   this.context.lineWidth = 5;
    //   this.context.strokeStyle = '#000000';
    // },
    // // ペンモード（赤）
    // penRed: function(){
    //   // カーソル変更
    //   this.canvasMode = 'penRed'

    //   // 描画設定
    //   this.context.lineCap = 'round';
    //   this.context.lineJoin = 'round';
    //   this.context.lineWidth = 5;
    //   this.context.strokeStyle = '#FF0000';
    // },
    // // ペンモード（青）
    // penBlue: function(){
    //   // カーソル変更
    //   this.canvasMode = 'penBlue'

    //   // 描画設定
    //   this.context.lineCap = 'round';
    //   this.context.lineJoin = 'round';
    //   this.context.lineWidth = 5;
    //   this.context.strokeStyle = '#0000FF';
    // },
    // 描画
    draw :function(e) {

      if(!this.isDrag) {
        return;
      }
      // this.pointX = (e.offsetX);
      // this.pointY = (e.offsetY);
      var target_rect = e.currentTarget.getBoundingClientRect();
      if(e.clientX){
        this.pointX = (e.offsetX);
        this.pointY = (e.offsetY);
      }else{
        e.preventDefault();
        this.pointX = e.touches[0].clientX - target_rect.left;
        this.pointY = e.touches[0].clientY - target_rect.top;
      }
      // console.log('draw draw', this.pointX, this.pointY, target_rect.left, target_rect.top);

      this.context.lineTo(this.pointX, this.pointY);
      this.context.stroke();
    },
    // 描画開始（mousedown）
    dragStart:function(e) {
      // var x = e.layerX
      // var y = e.layerY
      // console.log('dragStart', x, y);
      // x = x - this.canvasX;
      // y = y - this.canvasY;
      // let x = e.offsetX;
      // let y = e.offsetY;
      var target_rect = e.currentTarget.getBoundingClientRect();
      if(e.clientX){
        this.pointX = (e.offsetX);
        this.pointY = (e.offsetY);
      }else{
        e.preventDefault();
        this.pointX = e.touches[0].clientX - target_rect.left;
        this.pointY = e.touches[0].clientY - target_rect.top;
      }

      // this.context.lineCap = 'round';
      // this.context.lineJoin = 'round';
      // this.context.lineWidth = 5;
      // this.context.strokeStyle = '#000000';

      // console.log('dragStart dragStart', this.pointX, this.pointY, e.offsetX, e.offsetY, target_rect.left, target_rect.top);
      this.context.beginPath();
      this.context.lineTo(this.pointX, this.pointY);

      this.isDrag = true;
    },
    // 描画終了（mouseup, mouseout）
    dragEnd: function() {
      console.log('dragEnd');
      if(this.isDrag){
      console.log('dragEnd stroke');
      // this.context.stroke();
      this.context.closePath();
      this.isDrag = false;
      this.toData();
      }
      this.pointX = 0;
      this.pointY = 0;
    },
     // クリア
    clear: function() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
    handleClick: function(targetid) {
        const tmp = (this.modelValue)? this.modelValue: [];
        // tmp.push(this.modelValue);
        console.log(tmp, targetid, this.modelValue);
        if(tmp.indexOf(targetid) < 0){
          tmp.push(targetid);
        }else if (tmp.indexOf(targetid) >= 0){
          tmp.splice(tmp.indexOf(targetid), 1);
        }
        console.log('after push', tmp, targetid, this.modelValue);

				this.$emit('update:modelValue',tmp);
				this.$emit('parentFunc',tmp);
    }
  }
};
</script>
