import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// 状態`Auth`と状態`Board`をVuexのstateで一元管理できるよう定義する
const state = {
  auth: { // 状態`Auth`
    token: localStorage.getItem('token'), // `token`は locale storage から取得する
    userId: null // `userId`はnullで初期化
  },
  apidata: {
    account_department: [],
    pager: {},
    orderer: [],
    contractor: [],
    co_data: [],
    co_type: [],
    order: [],
    order_detail: [],
    order_detail_type: {},
    template: {},
    template_detail: [],
    order_deadline_base: {},
    order_deadline_day_type: {},
    order_detail_input_value_type: {},
    order_step: {},
    relation: [],
    category: [],
    tax: [],
    tag: [],
    tag_group: [],
    type: [],
    type_group: [],
    stock: [],
    ticket: [],
    ticket_line_type: [],
    discount: [],
    discount_type: [],
    device: [],
    receipt_setting: [],
    sales: [],
    sales_reports: [],
    financial_status: [],
    sales_article: [],
    sales_stock: [],
    payment_type: [],
    department: [],
    customer: [],
    event: [],
    ticket_limit_type: [],
    master_data: [],
    timesc_stat: [],
    course: [],
    thread:{},
    order_set_date: {},
    order_thread_type: {}
  },
}
export default createStore({
    strict: false,
    state,
    getters,
    actions,
    mutations,
    plugins: [createPersistedState({
        key: 'app',
        storage: window.sessionStorage
    })]
})
