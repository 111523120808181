<template>
    <main :class="displayClass">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'" :btnToLink="'/device/add'" :routerSw="true"></bs-page-title>
        <bs-msg-alert :alertClass="alertClass">{{this.alertMessage}}</bs-msg-alert>
        <h5>検索条件</h5>
        <div class="w100 mb-5">
            <Form @submit.prevent novaformate>
              <div class="row mb-3">
                <div class="col">
                  <label for="SprmCategory" class="form-label">発注元</label>
                  <bs-select
                    name="orderer_id"
                    :options="orderer_list"
                    v-model="sprm.orderer_id"
                    :null-option="true"
                    ></bs-select>
                </div>
                <div class="col">
                  <label for="SprmCategory" class="form-label">発注先</label>
                  <bs-select
                    name="contractor_id"
                    :options="contractor_list"
                    v-model="sprm.contractor_id"
                    :null-option="true"
                    ></bs-select>
                </div>
                <div class="col ">
                  <label for="SprmOrderStep" class="form-label">状況</label>
                  <bs-select
                    name="step"
                    :options="order_step"
                    v-model="sprm.step"
                    :null-option="true"
                    ></bs-select>
                </div>
                <div class="col ">
                  <label for="SprmSetDate" class="form-label">最終セット日</label>
                  <input type="date" id="SprmSetDate" v-model="sprm.set_date" class="form-control">
                </div>
              </div>
              <div class="mb-3 row">
                <div class="col ">
                  <label for="SprmOrderDate" class="form-label">発注日</label>
                  <input type="date" id="SprmOrderDate" v-model="sprm.order_date" class="form-control">
                </div>
                <div class="col ">
                  <label for="SprmPreferredDate" class="form-label">希望納期日</label>
                  <input type="date" id="SprmPreferredDate" v-model="sprm.delivery_preferred_date" class="form-control">
                </div>
                <div class="col ">
                  <label for="SprmScheduleDate" class="form-label">予定納品日</label>
                  <input type="date" id="SprmScheduleDate" v-model="sprm.delivery_schedule_date" class="form-control">
                </div>
                <div class="col ">
                  <label for="SprmDeliveryDate" class="form-label">納品日</label>
                  <input type="date" id="SprmDeliveryDate" v-model="sprm.delivery_date" class="form-control">
                </div>

              </div>
           </Form>
            <div class="row">
                <div class="col text-center">
                    <bs-button :btnClass="'btn-primary'" @to_click_event="searchOrder">条件で検索</bs-button>
                </div>
            </div>
        </div>
        <div class="w100">
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>状況</th>
                        <th>発注日</th>
                        <th>発注元</th>
                        <th>発注先</th>
                        <th>最終セット日</th>
                        <th>希望納期</th>
                        <th>予定納期</th>
                        <th>納品日</th>
                        <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="list in lists" :key="list.id">
                        <td>
                            <span :class="check_step(list).class">{{ check_step(list).message }}</span>
                        </td>
                        <td>{{ list.step_label }}</td>
                        <td>{{ list.order_date_str }}</td>
                        <td>{{ list.orderer_label }}</td>
                        <td>{{ list.contractor_label }}</td>
                        <td>{{ list.set_date_str }}</td>
                        <td>{{ list.delivery_preferred_date_str }}</td>
                        <td>{{ list.delivery_schedule_date_str }}</td>
                        <td>{{ list.delivery_date_str }}</td>
                        <td>
                            <router-link :to="{name: 'order_detail', params:{id:list.id}}" class="btn btn-light btn-outline-secondary" href="#">詳細</router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
// import BsPageNav from '../../molecules/BsPageNav.vue'
import BsSelect from '../../atoms/BsSelect.vue'
// import BsCheckBox from '../../atoms/BsCheckBox.vue'
import {  Form } from 'vee-validate';
import BsButton from '../../atoms/BsButton.vue'
import Common from '../../../common/common'
// import '../../assets/bootstrap/css/sc.css';

export default {
    name: 'DeviceListView',
    data: function () {
        return {
            'fromParentTitle': '注文データ一覧',
            'fromParentBtn': '',
            'email': '',
            'pass': '',
            'alertMessage': '',
            'alertClass': 'alert-info',
            error: false,
            'sprm': {
                'from_number': '',
                'to_number': '',
                'code': '',
                'contractor_id': '',
                'orderer_id': '',
                'label': '',
                'order_date': '',
                'set_date': '',
                'delivery_date': '',
                'delivery_preferred_date': '',
                'delivery_schedule_date': '',
                // 'stat': [1]
                step: '',
                },
        }
    },
  props: {
        displayClass: {
            type: String,
            default: 'col-md-9 ms-sm-auto col-lg-10 px-md-4'
        },
    },
    components: {
        BsPageTitle,
        BsMsgAlert,
        // BsPageNav,
        // Field,
        Form,
        BsSelect,
        BsButton,
        // BsCheckBox
    },
    computed: {
        ...mapState({
            lists: state => state.apidata.order,
            order_step: state => state.apidata.order_step,
            orderer_list: state => state.apidata.orderer,
            // author_id: state => state.auth.userId,
            contractor_list: state => state.apidata.contractor,
        }),
        check_step: function() {
            return (order) => {
                const todayNum = parseInt(Common.getTodayNum());
                    console.log('checkstepA',order);
                const ret = {
                    'class': '',
                    'message': ''
                }
                // 納品日が設定されている
                if(order.delivery_date_num != '0'){
                    console.log('checkstep',order.delivery_date_str);
                    ret['class'] = '';
                    ret['message'] = order.delivery_date_num;
                }
                // 現時点で予定納期、希望納期を過ぎている
                else if(
                    todayNum > parseInt(order.delivery_preferred_date_num)
                    && todayNum > parseInt(order.delivery_schedule_date_num)
                    ){
                        ret['class'] = 'badge bg-danger';
                        ret['message'] =  '納期超過';
                }
                // 現時点で予定納期を過ぎている
                else if(
                     todayNum > parseInt(order.delivery_schedule_date_num)
                    ){
                        ret['class'] = 'badge bg-warning';
                        ret['message'] =  '予定納期オーバー'
                }
                // 現時点で希望納期を過ぎている予定納期はまだ
                else if(
                     todayNum > parseInt(order.delivery_preferred_date_num)
                    ){
                        ret['class'] = 'badge bg-warning';
                        ret['message'] =  '希望オーバー';
                }
                else{
                    ret['class'] = '';
                    ret['message'] =  '-';
                }
                return ret;
            }
        },
    },

    created () {
        this.loadOrder();
        this.loadGetList('order_step');
        this.loadContractorList();
        this.loadOrdererList();
        // this.loadDepartment();
    },
    mounted: function(){
        // console.log(this.$route.path);
        // console.log('strage', this.$store.state.article, this.lists);
        // this.fromParentTitle = '商品詳細';
        this.alertMessage = '注文データ一覧です。';
    },

    methods: {
        loadOrdererList() {
            return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'orderer', targetF: 'select'})
                        .catch(err => Promise.reject(err))
                        .then(() => {
                            // console.log('loard complete couse_list', res);
                            console.log('this', this.$store.state.apidata.orderer);
                            // this.$router.push({ path: '/login' })
                        })
                        .finally(() => {
                            console.log('course finally', this.$store.state.apidata.orderer);
                        })
        },
        // 紐付きの発注先を取得
        loadContractorList() {
            return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'contractor', targetF: 'select'})
                        .catch(err => Promise.reject(err))
                        .then(() => {
                            // console.log('loard complete couse_list', res);
                            console.log('this', this.$store.state.apidata.contractor);
                            // this.$router.push({ path: '/login' })
                        })
                        .finally(() => {
                            console.log('course finally', this.$store.state.apidata.contractor);
                        })
        },
        loadGetList (target_name) {
            // console.log('tag');
            return this.$store.dispatch('fetchBasicLists', {data: {}, target: target_name})
                        .catch(err => Promise.reject(err))
                        .then((res) => {
                        console.log('loadGetList', target_name, res);
                        })
                        .finally(() => {})
        },
        searchOrder() {
            this.page = 0;
            this.loadOrder();

        },
        loadOrder () {
            const params = {};
console.log('sprm check', this.sprm);
            // if(this.sprm.to_number) params['to_number'] = this.sprm.to_number;
            if(this.sprm.contractor_id) params['contractor_id'] = this.sprm.contractor_id;
            if(this.sprm.orderer_id) params['orderer_id'] = this.sprm.orderer_id;
            if(this.sprm.step) params['step'] = this.sprm.step;
            if(this.sprm.order_date) params['order_date'] = this.sprm.order_date;
            if(this.sprm.set_date) params['set_date'] = this.sprm.set_date;
            if(this.sprm.delivery_date) params['delivery_date'] = this.sprm.delivery_date;
            if(this.sprm.delivery_preferred_date) params['delivery_preferred_date'] = this.sprm.delivery_preferred_date;
            if(this.sprm.delivery_schedule_date) params['delivery_schedule_date'] = this.sprm.delivery_schedule_date;
            if(this.page >= 0) params['p'] = this.page;
console.log('sprm check params', params);

            // console.log('tag');
            return this.$store.dispatch('fetchBasicLists', {data: params, target: 'order'})
                        .catch(err => Promise.reject(err))
                        .then(() => {
                            console.log('loard complete order');
                            console.log('this', this.$store.state.apidata.order);
                            // this.$router.push({ path: '/login' })
                        })
                        .finally(() => {
                            console.log('finally order', this.$store.state.apidata.order);
                        })
        },
        // loadDepartment () {
        //     // console.log('tag');
        //     return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'department'})
        //                 .catch(err => Promise.reject(err))
        //                 .then(() => {
        //                     console.log('loard complete department');
        //                     console.log('this', this.$store.state.apidata.department);
        //                     // this.$router.push({ path: '/login' })
        //                 })
        //                 .finally(() => {
        //                     console.log('finally department', this.$store.state.apidata.department);
        //                 })
        // },
    }

}
</script>
