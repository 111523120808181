export default {
    getArticleById: state => id => {
        //   const articles = [];
        //返す準備
        let article = '';
        // console.log('getter', id);
        // console.log('state.article.lists', state.article.lists);
        //state.articlのデータからIDが一致するものを取得
        state.apidata.article.forEach(list => {
        // articles.push(list);
        // console.log('list.items.id', list.id);
            if(id == list.id){
                article = list;
            }
        })
        // console.log('articles', articles);
        // const returndata = articles.find(article => article.id === id);
        // console.log('article', article);
        //一致するものがなければ返す
        if(article == '') article = false;
        return article;
    },
    getBasicById: state => (id, target) => {
        let data = '';
        console.log('getbasicbyid', target, state.apidata[target]);
        //stateのデータからIDが一致するものを取得
        if(Array.isArray(state.apidata[target])){
            state.apidata[target].forEach(list => {
                if(id == list.id){
                    data = list;
                }
            })
        }else{
           Object.keys(state.apidata[target]).forEach((tar_key) => {
                if(id == state.apidata[target][tar_key].id){
                    data = state.apidata[target][tar_key];
                }
            })
        }
        //一致するものがなければ返す
        if(data == '') data = false;
        return data;
    },
}
