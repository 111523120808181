export default Object.freeze({
    BASE_API_URL: process.env.NODE_ENV === 'production'
        ? process.env.VUE_APP_API_SERVER + ''
        : process.env.VUE_APP_API_SERVER,
    BASE_ASSETS_URL: process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_API_ASSETS
    : process.env.VUE_APP_API_ASSETS,
    BASE_API_HEADER_KEY: 'dental-manage-token',

    DISCOUNT_TYPE: {
        1: {
            'label': '割引',
            'key': '1'
        },
        2: {
            'label': '値引',
            'key': '2'
        },
    },
    BASIC_STAT_LIST: {
        // 0: {
        //     value: 0,
        //     label: '非表示'
        // },
        1: {
            value: 1,
            label: '表示'
        },
        9: {
            value: 9,
            label: '削除済'
        },
    }
});
