<template>
    <nav aria-label="Page navigation example">
        <ul class="pagination" v-if="pager[this.pager_key]">
            <li v-if="pager[pager_key].prev" class="page-item"><a class="page-link" href="#" @click="handleClick">{{prevLabel}}</a></li>
            <li class="page-item" v-for="(item, i) in pager[pager_key].center" :key="i"><a class="page-link" href="#" @click="handleClick(item.pageNum)">{{item.label}}</a></li>
            <li v-if="pager[pager_key].next" class="page-item"><a class="page-link" href="#" @click="handleClick">{{nextLabel}}</a></li>
        </ul>
    </nav>
</template>


<script>
import { mapState } from 'vuex'
// import BsButton from '../atoms/BsButton.vue'
// import BsRouterLink from '../atoms/BsRouterLink.vue'
export default {
    name: 'BsPageNav',
    props: {
        pageNum: {
            type: Number,
            default: 1
        },
        // fromNum: {
        //     type: Number,
        //     default: 10
        // },
        limitNum: {
            type: Number,
            default: 10
        },
        maxNum: {
            type: Number,
            default: 0
        },
        nextLabel: {
            type: String,
            default: '次'
        },
        prevLabel: {
            type: String,
            default: '前'
        },
        pager_key: {
            type: String,
            default: ''
        },
    },
    components: {
        // BsButton,
        // BsRouterLink,
    },
    methods: {
        handleClick(ev){
            console.log('parent',  ev,);
            this.$emit('to_click_event', ev)
            // if(this.routerSw){
            //     this.$router.push(this.btnToLink);
            // }
        }
    },
    computed: mapState({
        // article: state => state.apidata.article,
        pager: state => state.apidata.pager,
    }),

        // prevLinkCheck(){
        //     if(this.pageNum != 0){
        //         return true;
        //     }else{
        //         return false;
        //     }
        // },
        // nextLinkCheck(){
        //     if((this.pageNum * this.limitNum) < (this.maxNum - this.limitNum)){
        //         return true;
        //     }else{
        //         return false;
        //     }
        // },
        // fromNum(){
        //     //指定ページの表示開始ナンバー
        //     return this.pageNum * this.limitNum;
        // },
        // createNavItem() {
        //     //返答内容
        //     // ret_page = {
        //     //                 'next': '',
        //     //                 'prev':'',
        //     //                 'center': []
        //     // }
        //     //ページ配列
        //     const linkNum = [];
        //     // ページ最大値
        //     const maxPage = Math.ceil(this.maxNum / this.limitNum);
        //     // ページ生成
        //     for(let a=0;a<maxPage;a++){
        //         linkNum.push(a+1);
        //     }
        //     return linkNum;

        //     // if(linkNum.length > 0){
        //     //     foreach($link_num as $key => $val){
        //     //         //中央部のボタン
        //     //         if(
        //     //             //最初のページ
        //     //             $key == $iPage
        //     //             //最初のページから$btnNumの数の分前後に
        //     //             || $key <= ($iPage + $btnNum) && $key >= ($iPage - $btnNum)
        //     //         ){
        //     //             //選択中
        //     //             if($key == $iPage){
        //     //                 $ret_page['center'][$key] = '<li class="active"><a href="#">'. $val. '</a></li>';
        //     //             }else{
        //     //                 $ret_page['center'][$key] = '<li><a href="'. $sLink. '&p='. $key. $sSharp. '" class="btnLink">'. $val. '</a></li>';
        //     //             }
        //     //         }
        //     //         //表示されたリンクボタンの一つ前後-省略記号
        //     //         elseif(
        //     //             $key == ($iPage + $btnNum + 1)
        //     //             || $key == ($iPage - $btnNum - 1)
        //     //         ){
        //     //             $ret_page['center'][$key] = '<li class="disabled"><a href="#">...</a></li>';
        //     //         }
        //     //     }
        //     // }
        // },
    // },
}
</script>
