<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'" :btnToLink="'/order_step/add'" :routerSw="true"></bs-page-title>
        <bs-msg-alert :alertClass="alertClass">{{this.alertMessage}}</bs-msg-alert>
       <div class="w100">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width:50%;">工程名称</th>
                        <th style="width:30%;">色</th>
                        <th style="width:10%;">action</th>
                        <th style="width:10%;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="list in lists" :key="list.id">
                        <td>{{ list.label }}</td>
                        <td><span class="d-inline-block py-1 px-5" :style="'background-color:#' + this.color + ';'">{{ list.color }}</span></td>
                        <td>
                            <router-link :to="{name: 'order_step_detail', params:{id:list.id}}"  href="#" class="btn btn-light btn-outline-secondary">詳細</router-link>
                        </td>
                        <td>
                            <button @click="delData(list.id, index)" class="btn btn-danger">削除</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <bs-page-nav :pager_key="'order_step'" @to_click_event="pageAction"></bs-page-nav>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
import BsPageNav from '../../molecules/BsPageNav.vue'
import Common from '../../../common/common'
// import '../../assets/bootstrap/css/sc.css';

export default {
    name: 'OrderStepListView',
    data: function () {
        return {
            'fromParentTitle': '工程',
            'fromParentBtn': '工程登録',
            'alertMessage': '',
            'alertClass': 'alert-info',
            'page': 0,
            'sprm': {
                'label': '',
                'stat': [1],
                'pageorder': 'sort',
                },
            error: false
        }
    },
    // props: {
    //     lists: {
    //         type: Array,
    //         default: () => []
    //     }
    // },
    components: {
        BsPageTitle,
        BsMsgAlert,
        BsPageNav,
    },
    computed: mapState({
        lists: state => state.apidata.order_step
    }),

    created () {
        this.loadOrderStep();
    },
    mounted: function(){
        // console.log(this.$route.path);
        // console.log('strage', this.$store.state.article, this.lists);
        // this.fromParentTitle = '商品詳細';
        this.alertMessage = '工程データ一覧です';
    },

    methods: {
        delData(target, index){
            if(confirm('削除しますか？')){
                const data = !Number.isNaN(target)
                ? {...this.$store.getters.getBasicById(target, 'order_step')}
                : {};
                // console.log('ret ticket data' ,ticket);
                data['stat'] = 9;
                return this.$store.dispatch('updateBasic', {data: data , target: 'order_step'})
                    .then(() => {
                        this.alertMessage = '削除しました';
                        this.alertClass = 'alert-success';
                        Common.moveToTop();
                    })
                    .catch((err) => {
                        // Promise.reject(err)
                        console.log(err);
                        this.alertMessage = '削除できませんでした';
                        this.alertClass = 'alert-danger';
                        Common.moveToTop();
                    })
                    .finally(() => {
                        // delete this.lists[index];
                        // this.lists.slice(index, 1);
                        console.log('delete finally', index);
                        this.loadorder_step();
                    });
            }
        },
        pageAction(page){
            console.log('pageAction', page);
            this.page = page;
            this.loadDiscount();
        },
        loadOrderStep () {
            const params = {};
            params['stat'] = this.sprm.stat;
            params['pageorder'] = this.sprm.pageorder;
            if(this.page > 0) params['p'] = this.page;
            return this.$store.dispatch('fetchBasicLists', {data: params, target: 'order_step'})
                        .catch(err => Promise.reject(err))
                        .then(() => {
                            console.log('loard complete order_step');
                            console.log('this', this.$store.state.apidata.order_step);
                            // this.$router.push({ path: '/login' })
                        })
                        .finally(() => {
                            console.log('finally', this.$store.state.order_step);
                        })
        }
    }

}
</script>
