<template>
    <main :class="displayClass" ref="mainwrap">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'"></bs-page-title>
        <bs-msg-alert :alertClass="alertClass">{{this.alertMessage}}</bs-msg-alert>
                            <!-- <bs-canvas
                            v-model="cvData"
                            :edit-sw="true"
                            :canvas-w="canvasW"
                            :canvas-h="canvasH"
                            :canvas-image-path="'/img/pen_input/2100.png'"></bs-canvas>
                            <input type="text" v-model="cvData"> -->
        <div class="w100">
            <div class="row mb-3">
              <div class="col-md-4 offset-md-8">
                <div class="form-check form-switch ">
                  <input class="form-check-input" v-model="form_info_sw" type="checkbox" id="flexSwitchCheckDefault">
                  <label class="form-check-label" for="flexSwitchCheckDefault">各フォームの説明を表示する</label>
                </div>
              </div>
            </div>
            <form @submit.prevent>
              <div class="row mb-3">
                <div class="col-md-12 border-bottom py-3">
                  <h3 for="OrdererId" class="form-label mb-3">発注主</h3>
                  <p class="alert alert-info" v-if="form_info_sw">発注元/納品先の医院を選択して下さい</p>
                  <bs-select-button
                    name="orderer_id"
                    :options="orderer_list"
                    v-model="orderer_id"
                    setClass="mb-3 "
                  ></bs-select-button>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-12 border-bottom py-3">
                  <h3 for="ContractorId" class="form-label">発注先</h3>
                  <p class="alert alert-info" v-if="form_info_sw">発注先の技工所を選択して下さい</p>
                  <bs-select-button
                    name="contractor_id"
                    :options="contractor_list"
                    v-model="contractor_id"
                    setClass="mb-3 "
                  ></bs-select-button>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <h3 for="OrderDate" class="form-label">日付</h3>
                  <p class="alert alert-info" v-if="form_info_sw">発注日を選択して下さい</p>
                  <input type="date" class="form-control" id="OrderDate" placeholder="デバイス名" v-model="order_date">
                </div>
                <div class="col-md-4 mb-2">
                  <h3 for="OrderTemplate" class="form-label">発注書選択</h3>
                  <p class="alert alert-info" v-if="form_info_sw">発注書テンプレートを選択して下さい</p>
                  <bs-select
                      :name="'template_id'"
                      :options="order_template"
                      v-model="template_id"
                      :null-option="true"
                      id="OrderTemplate"
                      @parentFunc="templateChange($event)"
                      ></bs-select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <h3 for="" class="form-label">発注内容</h3>
                  <p class="alert alert-info" v-if="form_info_sw">発注書です</p>
                  <div class="row border py-5 px-3" v-if="template_id > 0 && Object.keys(order_template).length > 0">
                    <h4 class="text-center mb-4">{{template_id}}/{{order_template[template_id].label}}</h4>
                      <div v-if="template_id > 0 && Object.keys(order_detail).length > 0">
                        <div class="col-12 mb-5" v-for="(detail, index) in order_detail" :key="index">
                          <h5 class="subsub bg-dark py-2 px-2 text-white border-bottom" v-if="detail.order_detail_type == 10">■{{detail.subject}}</h5>
                          <h5 v-else>{{detail.subject}}</h5>
                          <p class="alert alert-info" v-if="form_info_sw && detail.description && detail.description.length > 0">{{detail.description}}</p>
                          <input type="hidden" :name="order">
                          <div v-if="detail.order_detail_type == 10">

                          </div>
                          <div v-else-if="detail.order_detail_type == 100">
                            <input type="text" class="form-control" v-model="detail.value">
                          </div>
                          <div v-else-if="detail.order_detail_type == 110">
                            <input type="number" class="form-control" v-model="detail.value">
                          </div>
                          <div v-else-if="detail.order_detail_type == 190">
                            <input type="text" class="form-control" v-model="detail.value">
                          </div>
                          <div v-else-if="detail.order_detail_type == 200">
                            <textarea class="form-control" v-model="detail.value"></textarea>
                          </div>
                          <div v-else-if="detail.order_detail_type == 300">
                            <bs-select
                                :options="detail.input_data"
                                v-model="detail.value"
                                :null-option="true"
                                id="OrderTemplate"
                                ></bs-select>
                          </div>
                          <div v-else-if="detail.order_detail_type == 400">
                            <bs-select-button-multi
                              :name="'detail[' + index + '][]'"
                              :options="detail.input_data"
                              v-model="detail.value"
                              :null-option="true"
                              id=""
                              ></bs-select-button-multi>
                          </div>
                          <div v-else-if="detail.order_detail_type == 500">
                            <bs-select-button
                              name="contractor_id"
                              :options="detail.input_data"
                              v-model="detail.value"
                            ></bs-select-button>
                          </div>
                          <div v-else-if="detail.order_detail_type >= 5000">
                            <bs-canvas
                            v-model="detail.value"
                            :edit-sw="true"
                            :canvas-w="canvasW"
                            :canvas-h="canvasW"
                            :canvas-image-path="detail.bg_inputs"></bs-canvas>
                          </div>
                          <div v-else-if="detail.order_detail_type >= 2000">
                            <bs-canvas
                            v-model="detail.value"
                            :edit-sw="true"
                            :canvas-w="canvasW"
                            :canvas-h="canvasH"
                            :canvas-image-path="'/img/pen_input/' + detail.order_detail_type + '.png'"></bs-canvas>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-sm-4 mb-2">
                  <router-link to="/order/list" class="btn btn-light btn-outline-secondary">戻る</router-link>
                </div>
                <div class="col-12 col-sm-4 mb-2 text-center">
                  <button type="submit" @click="update" class="btn btn-primary">{{btnLabel}}</button>
                </div>
              </div>
            </form>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
import BsSelect from '../../atoms/BsSelect.vue'
import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
import BsSelectButton from '../../atoms/BsSelectButton.vue'
import BsSelectButtonMulti from '../../atoms/BsSelectButtonMulti.vue'
import BsCanvas from '../../atoms/BsCanvas.vue'
import Common from '../../../common/common'
// import BsCheckBox from '../../atoms/BsCheckBox.vue'
// import '../../assets/bootstrap/css/sc.css';

export default {
  name: 'OrderDetailView',
  data: function () {
      return {
        'targetid': this.$route.params.id,
        'fromParentTitle': '発注',
        'fromParentBtn': '',
        'alertMessage': '',
        'alertClass': 'alert-info',
        'order': {},
        'order_detail': {},
        'add': true,
        'uploadFile': {},
        'orderer_id': 0,
        'contractor_id': 0,
        'template_id': 0,
        'order_date': Common.getToday(),
        'form_info_sw': false,
        'author_id': 0,
        'subject': '',
        'body': '',
        cvData: '',
        'canvasH': 0,
        'canvasW': 0,
        // order_template_list: {},
      }
  },
  components: {
    BsPageTitle,
    BsSelect,
    BsMsgAlert,
    BsSelectButton,
    // BsCheckBox,
    BsSelectButtonMulti,
    BsCanvas,
},
  methods: {
    contractorChange: function(){
      // this.template_id = 0;
    },
    templateChange: function(e) {
      console.log('templateChange change',e , this.order_template);
      console.log('templateChange change',e , this.contractor_id);
      // this.template_id = e;
      console.log('templateChange order =', e, this.order_template[this.template_id]);
      this.order_detail = this.order_template[this.template_id].details;
      console.log('templateChange order =>', e, this.order_detail);
      // alert(e);
    },
    selectedFile: function(e) {
      e.preventDefault();
      let files = e.target.files;
      console.log('selectedFile', typeof(files),  files);
      if(files){
      this.uploadFile = files[0];
      }
    },
    loadOrdererList() {
        return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'orderer', targetF: 'select'})
                    .catch(err => Promise.reject(err))
                    .then(() => {
                        // console.log('loard complete couse_list', res);
                        console.log('this', this.$store.state.apidata.orderer);
                        // this.$router.push({ path: '/login' })
                    })
                    .finally(() => {
                        console.log('course finally', this.$store.state.apidata.orderer);
                    })
    },
    // 紐付きの発注先を取得
    loadContractorList() {
        return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'contractor', targetF: 'select'})
                    .catch(err => Promise.reject(err))
                    .then(() => {
                        // console.log('loard complete couse_list', res);
                        console.log('this', this.$store.state.apidata.contractor);
                        // this.$router.push({ path: '/login' })
                    })
                    .finally(() => {
                        console.log('course finally', this.$store.state.apidata.contractor);
                    })
    },
    // 紐付きの発注先を取得
    loadTemplateList() {
        return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'template', targetF: 'lists'})
                    .catch(err => Promise.reject(err))
                    .then(() => {
                        // console.log('loard complete couse_list', res);
                        console.log('this', this.$store.state.apidata.template);
                        // this.$router.push({ path: '/login' })
                    })
                    .finally(() => {
                        console.log('template finally', this.$store.state.apidata.template);
                    })
    },
    loadOrder (post) {
        // console.log('tag');
        return this.$store.dispatch('fetchBasicLists', {data: post, target: 'order'})
                    .catch(err => Promise.reject(err))
                    .then(() => {
                        console.log('loard complete order');
                        console.log('this', this.$store.state.apidata.order);
                        // this.$router.push({ path: '/login' })
                    })
                    .finally(() => {
                        // console.log('finally order', this.$store.state.apidata.device);
                      if(this.targetid){
                        this.$router.push({ path: '/order/' + this.targetid })
                      }
                    })
    },
    update(){
      console.log('component methods', this.order_detail);

      const post = {
        'orderer_id': this.orderer_id,
        'contractor_id': this.contractor_id,
        'template_id': this.template_id,
        'order_date': this.order_date,
        'subject': this.subject,
        'body': this.body,
        'author_id': this.author_id,
        'order_detail': this.order_detail,
        'sendmail_flg': 1,
      }
      // this.device.receipt_logo_image = this.uploadFile;
      // console.log('upload upload receipt', this.uploadFile, this.device.receipt_logo_image);
      return this.$store.dispatch('updateBasic', {data: post, target: 'order'})
        .then((res) => {
          console.log('res update', res);
          this.targetid = res.data.body.id;
          this.loadOrder({});
          // this.$store.state.apidata.order = [res.data.body];
          // this.back()
          this.alertMessage = this.btnLabel + 'しました';
          this.alertClass = 'alert-success';
          // Common.moveToTop();
        })
        .catch(
          (err) => {
          // err => Promise.reject(err);
          console.log('error update', err);
            this.alertMessage = this.btnLabel + 'できませんでした';
            this.alertClass = 'alert-danger';
            // Common.moveToTop();
          }
        )
        .finally(() => {
        });
    }
  },
  computed: {
    ...mapState({
    // order: state => state.apidata.order,
      orderer_list: state => state.apidata.orderer,
      author_id: state => state.auth.userId,
      contractor_list: state => state.apidata.contractor,
      order_template(state) {
        if(this.contractor_id > 0 && Object.keys(state.apidata.template).length > 0){
          this.template_id = 0;
            const val = Object.keys(state.apidata.template).reduce((tmp, tmp_id) => {
              console.log('reduce', tmp, tmp_id);
              if(state.apidata.template[tmp_id].contractor_id == this.contractor_id && state.apidata.template[tmp_id].parent_id==0){
              console.log('reduce if ', state.apidata.template[tmp_id], {[tmp_id]: state.apidata.template[tmp_id]});
                tmp[tmp_id] = state.apidata.template[tmp_id];
                tmp[tmp_id]['label'] += '[' + tmp_id + ']';
             }
              return tmp;
            }, {})
            ;
            console.log('reduce val ', val);
            return val
        }else{
          return false;
        }
      },
    // order_template: state => state.apidata.template_id,
    }),
    order_template_list: function() {
      if(this.contractor_id > 0){
        return this.order_template;
      }else{
        return false;
      }
    },
    btnLabel() {
        if(this.add){
            return '新規発注';
        }
        else{
            return '更新';
        }
    },
  },
  created () {
    // this.loadCourse();
    // this.loadDepartment();
    this.loadContractorList();
    this.loadOrdererList();
    this.loadTemplateList();
    if(this.targetid == 'add'){
      // console.log('add');
      // console.log('this.article', this.article);
      // this.order = {
      // };
      // console.log('this.type', this.type);
    }else{
      const ret = !Number.isNaN(this.targetid)
        ? {...this.$store.getters.getBasicById(this.targetid, 'order')}
        : {};
      if(Object.keys(ret).length > 0){
        this.add = false;
      }
      this.order = ret;
    }
  },
  mounted: function(){
      // console.log(this.$route.path);
      // console.log('strage', this.$store.state.article);
      // console.log('this', ...this.$store.getters.getArticleById(1));
      this.fromParentTitle = '新規発注';
      this.alertMessage = this.btnLabel + 'できます';

      // this.canvasW = this.$refs.mainwrap.clientWidth;
      // this.canvasH = this.$refs.mainwrap.clientHeight;


      // console.log('tags_list', this.tags_list);
  },

  props: {
        displayClass: {
            type: String,
            default: 'col-md-9 ms-sm-auto col-lg-10 px-md-4'
        },
    },

}
</script>
