<template>
    <main :class="displayClass">
        <bs-page-title :titleLabel="'ダッシュボード'" :btnLabel="''"></bs-page-title>

    </main>
</template>

<script>
import BsPageTitle from '../molecules/BsPageTitle.vue'

export default {
    name: 'BsDashBoardView',
    data: function () {
        return {
        }
    },
    props: {
        displayClass: {
            type: String,
            default: 'col-md-9 ms-sm-auto col-lg-10 px-md-4'
        },
    },
    components: {
        BsPageTitle,
    },
}
</script>
