<template>
   <Field
		as="select"
		:value="modelValue"
		:name="name"
		@change="onChange($event)"
        class="form-control form-select"
	>
		<option v-if="nullOption" value="">
			{{nullOptionLabel}}/{{modelValue}}
		</option>
		<option
			v-for="(option,index) in options"
			:key="index"
			:value="option.key"
			:selected="(option.key == modelValue)? 'selected': ''">
			{{ option.label }}
		</option>
   </Field>
</template>

<script>
	import { Field } from 'vee-validate';
    export default {
        name: "BsSelect",
        props: ['options','modelValue', 'name', 'nullOption'],
		// props: {
		// 	options: {
		// 		type: Array,
		// 		require: false
		// 	},
		// 	modelValue: {
		// 		type: String,
		// 		require: false
		// 	},
		// 	name: {
		// 		type: String,
		// 		require: false
		// 	},
		// 	nullOption: {
		// 		type: Boolean,
		// 		require:
		// 	}
		// },
		components: {
			Field,
		},
		computed:{
			nullOptionLabel: function () {
				if(this.nullOption != true && this.nullOption != false){
					return this.nullOption + '!';
				}else{
					return '選択して下さい';
				}
			}
		},
		methods: {
			onChange(e) {
				console.log('bsselect on change');
				console.log(e.target);
				console.log(e.target.value);
				this.$emit('update:modelValue',e.target.value);
				this.$emit('parentFunc',e.target.value);
			}
		}
    }
</script>
