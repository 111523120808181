<template>
    <main :class="displayClass">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'" :btnToLink="'/department/add'" :routerSw="true"></bs-page-title>
        <div class="w100">
            <table class="table">
                <thead>
                    <tr>
                        <th>所属先名称</th>
                        <th>種別</th>
                        <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="list in lists" :key="list.id">
                        <td>{{ list.label }}</td>
                        <td>{{ list.type_label }}</td>
                        <td>
                            <router-link :to="{name: 'co_detail', params:{id:list.id}}" class="btn btn-light btn-outline-secondary btn-sm" href="#">詳細</router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
// import '../../assets/bootstrap/css/sc.css';

export default {
    name: 'CotListView',
    data: function () {
        return {
            'fromParentTitle': '所属先一覧',
            'fromParentBtn': '',
            'email': '',
            'pass': '',
            error: false
        }
    },
  props: {
        displayClass: {
            type: String,
            default: 'col-md-9 ms-sm-auto col-lg-10 px-md-4'
        },
    },
    components: {
        BsPageTitle,
    },
    computed: mapState({
        lists: state => state.apidata.co_data
    }),

    created () {
        this.loadDepartment();
    },
    mounted: function(){
        // console.log(this.$route.path);
        // console.log('strage', this.$store.state.article, this.lists);
        // this.fromParentTitle = '商品詳細';
    },

    methods: {
        loadDepartment () {
            // console.log('tag');
            return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'co_data'})
                        .catch(err => Promise.reject(err))
                        .then(() => {
                            console.log('loard complete department');
                            // console.log('this', this.$store.state.apidata.department);
                            // this.$router.push({ path: '/login' })
                        })
                        .finally(() => {
                            // console.log('finally', this.$store.state.apidata.department);
                        })
        },
        // loadGroups () {
        //     // console.log('tag');
        //     return this.$store.dispatch('fetchTagGroupLists', {data: {}})
        //                 .catch(err => Promise.reject(err))
        //                 .then(() => {
        //                     console.log('loard complete tag');
        //                     console.log('thistg', this.$store.state.apidata.tag_group);
        //                     // this.$router.push({ path: '/login' })
        //                 })
        //                 .finally(() => {
        //                     console.log('finallytg', this.$store.state.apidata.tag_group);
        //                 })
        // }
    }

}
</script>
