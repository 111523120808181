<template>
    <main :class="displayClass">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'"></bs-page-title>
        <bs-msg-alert :alertClass="alertClass">{{alertMessage}}</bs-msg-alert>
        <div class="w100">
            <form @submit.prevent>
              <div class="row mb-3">
                <div class="col">
                  <label for="Label" class="form-label">名称</label>
                  <input type="text" class="form-control" id="Label" placeholder="テンプレート名称" v-model="template.label">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <label for="OrdererId" class="form-label">発注主</label>
                  <bs-select
                    :options="orderer_list"
                    v-model="template.orderer_id"></bs-select>
                  <!-- <bs-select-button
                    name="orderer_id"
                    :options="orderer_list"
                    v-model="orderer_id"
                  ></bs-select-button> -->
                </div>
                <div class="col-md-6">
                  <label for="ContractorId" class="form-label">発注先</label>
                  <bs-select
                    :options="contractor_list"
                    v-model="template.contractor_id"></bs-select>
                  <!-- <bs-select-button
                    name="contractor_id"
                    :options="contractor_list"
                    v-model="contractor_id"
                  ></bs-select-button> -->
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label for="OrderDeadlineBase" class="form-label">発注起算日</label>
                  <bs-select
                    id="OrderDeadlineBase"
                    :options="order_deadline_base"
                    v-model="template.deadline_base"></bs-select>
                </div>
                <div class="col-md-4">
                  <label for="OrderDeadlineDayType" class="form-label">発注加算日種別</label>
                  <bs-select
                    id="OrderDeadlineDayType"
                    :options="order_deadline_day_type"
                    v-model="template.deadline_day_type"></bs-select>
                </div>
                <div class="col-md-4">
                  <label for="OrderDeadlineDayValue" class="form-label">基本納品日数</label>
                  <input type="number" class="form-control" id="OrderDeadlineDayValue" placeholder="" v-model="template.deadline_day_value">
                </div>
              </div>

              <h3>テンプレート詳細</h3>
              <p class="alert alert-info">
                発注書テンプレートです。
              </p>
              <div class="row mb-5">
                <ul class="nav nav-tabs nav-fill">
                  <li class="nav-item">
                    <a :class="tab_switch('data')" @click="tab_switch_action('data')" href="#">フォーム入力</a>
                  </li>
                  <li class="nav-item">
                    <a :class="tab_switch('sort')" @click="tab_switch_action('sort')" href="#">フォーム順序変更</a>
                  </li>
                </ul>
              </div>
              <div :class="tabDisplay('sort', 'row mb-3')">
                <draggable tag="ul" :list='details' group="details_grp" @start="drag_start" @end="drag_end" item-key="id" class="list-group">
                  <template #item="{element}">
                        <li class="list-group-item">
                          [{{element.sort}}] {{element.subject}}
                        </li>
                  </template>
                </draggable>
              </div>
              <div :class="tabDisplay('data', 'row mb-3')">
                <div class="col-12" v-for="(detail, detail_index) in details" :key="detail_index">
                  <div class="mb-3 me-2 p-2 border">
                    <div class="row">
                      <div class="col-5 mb-2">
                        <label :for="'detail_subject_'+detail.id" class="form-label">フォームタイトル</label>
                        <input type="text" v-model="detail.subject" placeholder="タイトル" :id="'detail_subject_'+detail.id" class="form-control">
                      </div>
                      <div class=" d-none col-2 mb-2">
                        <label :for="'detail_sort_'+detail.id" class="form-label">表示順</label>
                        <input type="number" v-model="detail.sort" placeholder="ソート" :id="'detail_sort_'+detail.id" class="form-control">
                      </div>
                      <div class="col-md-3 mb-2">
                        <label :for="'detail_order_detail_type_'+detail.id" class="form-label">入力フォーム種別</label>
                        <bs-select
                          name="order_detail_type"
                          :options="order_detail_type"
                          v-model="detail.order_detail_type"
                          :null-option="false" :id="'detail_order_detail_type_'+detail.id"></bs-select>
                      </div>
                      <div class="col-md-4 mb-2">
                        <label :for="'detail_input_value_type_'+detail.id" class="form-label">納期追加種別</label>
                        <bs-select
                          name="order_detail_type"
                          :options="order_detail_input_value_type"
                          v-model="detail.order_detail_input_value_type"
                          :null-option="false" :id="'detail_input_value_type_'+detail.id"></bs-select>
                      </div>
                    </div>
                    <div class="row" v-if="detail.order_detail_type != 10">
                      <div class="col-md-5 mb-2">
                        <div class="form-check form-switch">
                          <label :for="'detail_description_'+detail.id" class="form-label">説明を入力する<input class="form-check-input" v-model="detail.description_sw" type="checkbox" :id="'description_sw_' + detail.id"></label>
                        </div>
                      </div>
                      <div class="col-md-7 mb-2 ">
                        <p>
                          発注時にこのフォームの説明を設定します。
                        </p>
                      </div>
                      <div class="col-md-12 mb-2" v-if="detail.description_sw">
                        <textarea v-model="detail.description" :id="'detail_description_'+detail.id" class="form-control"></textarea>
                      </div>
                    </div>
                    <div class="row" v-if="detail_list_form_chk(detail.order_detail_type)">
                      <div class="col mb-2">
                        <label :for="'detail_input_data_'+detail.id" class="form-label">選択データ</label>
                        <p class="alert alert-warning">
                          発注時に発注者が選ぶ選択肢を設定します。
                        </p>
                        <draggable v-model='detail.input_data' :group="'input_data_grp' + detail.id" @start="drag_start" :id="'input_data_' + detail_index" @end="drag_end2" :target-index="detail_index" item-key="id" class="">
                          <template #item="{element, index}">
                            <div class="row mb-2">
                              <div class="col-5 mb-1">
                                <label class="form-label">ラベル</label>
                                <input type="text" v-model="element.label" class="form-control">
                              </div>
                              <div class="col-2 mb-1">
                                <label class="form-label">値</label>
                                <input type="text" v-model="element.value" class="form-control">
                              </div>
                              <div class="col-3 mb-1">
                                <label class="form-label">加算日数</label>
                                <input type="number" v-model="element.deadline_add_day" class="form-control">
                              </div>
                              <div class="col-2 mb-1">
                                <label class="form-label">action</label><br>
                                <button type="submit" @click="del_list(detail_index, index)" class="btn btn-danger btn-sm">削除</button>
                              </div>
                            </div>
                          </template>
                        </draggable>
                        <div>
                          <button type="submit" @click="add_list(detail.id, detail_index)" class="btn btn-info btn-sm">リスト追加</button>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="detail.order_detail_type == 10">

                    </div>
                    <div v-else-if="detail_list_form_chk_pen_input_5000(detail.order_detail_type)">
                      <div class="row">
                        <div class="col-6">
                          <div v-if="detail.bg_inputs != ''">
                          <bs-canvas
                            :canvas-w="0"
                            :canvas-h="500"
                            :edit-sw="false"
                            :canvas-image-path="detail.bg_inputs"></bs-canvas>
                            {{detail.bg_inputs}}
                          </div>
                        </div>
                        <div class="col-4">
                          <input type="file" class="form-control" :id="'pen_input_bgfile_' + detail_index" name="receipt_logo_image" @change="selectedFile(detail_index)">
                        </div>
                        <div class="col-2">
                          <button type="button" class="btn btn-warning btn-sm">ファイル削除</button>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="detail_list_form_chk_pen_input(detail.order_detail_type)">
                      <div class="w-100">
                        <img :src="'/img/pen_input/' + detail.order_detail_type + '.png'" class="w-100">
                      </div>
                      {{detail.order_detail_type}}
                    </div>
                    <div v-else>
                      <div v-if="!detail.order_detail_type">
                        <p class="alert alert-warning">
                          入力フォーム種別を選択して下さい。
                        </p>
                      </div>
                      <div class="col mb-2" v-else-if="Object.keys(detail.input_data).length > 0">
                        <label :for="'detail_input_data_'+detail.id" class="form-label">入力データ</label>
                        <p class="alert alert-warning">
                          フォームに発注者がデータを入力してもらいます。
                        </p>

                        <div v-for="(value, key) in detail.input_data" :key="key" class="row mb-2">
                          <div class="col-4 text-end mb-1">
                            <label class="form-label">注釈</label>
                          </div>
                          <div class="col-8 mb-1">
                            <input type="text" v-model="detail.input_data[key].value" class="form-control">
                          </div>
                          <div class="col-4 text-end mb-1" v-if="detail.order_detail_input_value_type == 100">
                            <label class="form-label">加算日数</label>
                          </div>
                          <div class="col-8 mb-1" v-if="detail.order_detail_input_value_type == 100">
                            <input type="text" v-model="detail.input_data[key].deadline_add_day" class="form-control">
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <p class="alert alert-warning">
                          発注時、データを直接入力してもらうフォームです。
                        </p>
                      </div>
                    </div>
                    <div class="text-end">
                      <a @click="delForm(detail_index)" class="btn btn-danger btn-sm">削除</a>
                    </div>
                  </div>
                </div>
              </div>
              <div :class="tabDisplay('data', 'row border-bottom mb-4 py-4')">
                <div class="">
                  <button type="submit" @click="add_form" class="btn btn-warning">フォーム追加</button>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12 col-sm-4 mb-2">
                  <router-link to="/template/list" class="btn btn-light btn-outline-secondary">戻る</router-link>
                </div>
                <div class="col-12 col-sm-4 mb-2 text-center">
                  <button type="submit" @click="update" class="btn btn-primary">{{btnLabel}}</button>
                </div>
              </div>
            </form>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
// import BsSelectButton from '../../atoms/BsSelectButton.vue'
import BsSelect from '../../atoms/BsSelect.vue'
import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
// import BsCheckBox from '../../atoms/BsCheckBox.vue'
// import '../../assets/bootstrap/css/sc.css';
import Common from '../../../common/common'
import BsCanvas from '../../atoms/BsCanvas.vue'
import draggable from "vuedraggable"
import config from '../../../const'
import axios from 'axios'

export default {
  name: 'TagDetailView',
  data: function () {
      return {
        'alertMessage': '',
        'alertClass': 'alert-info',
        'add': true,
        'targetid': this.$route.params.id,
        'fromParentTitle': 'テンプレートデータ',
        'fromParentBtn': '',
        'orderer_id': 0,
        'contractor_id': 0,
        'template': {},
        'details': {},
        'detail_cnt': 0,
        'detail_input_cnt': {},
        'tab_active': 'data',
        // 'uploadFile': {},
      }
  },
  components: {
      BsPageTitle,
      // BsSelectButton,
      BsSelect,
      // BsCheckBox,
      BsCanvas,
      BsMsgAlert,
      draggable,
  },
  methods: {
    selectedFile(indexx) {
      const files = event.target.files;
      console.log('selectedFile!', typeof(files), files, indexx);
      event.preventDefault();
      // let files = e.target.files;
      // console.log('selectedFile', typeof(files), event.currentTarget.result);
      if(files){
        // 読み込み完了時のイベント
        const detail = this.details[indexx];
        const header = {
          [config.BASE_API_HEADER_KEY]: this.$store.state.auth.token
        };
        const form = new FormData();
        form.append('file', files[0]);
        axios.post(
          config.BASE_API_URL + '/upload/template_bg_inputs',
          form,
          {
            headers: header
          }
        )
        .then(res => {
          console.log(res);
          if(res && res.status == 200){
            detail.bg_inputs = res.data.body.file_path;
            detail.bg_inputs_json = res.data.body;
          }
        })
        .catch(error => {
          console.log(error);
        });

        // // FileReaderを作成
        // const fileReader = new FileReader() ;
        // fileReader.onload = function () {
        //   console.log('a1', detail);
        //   console.log(indexx, detail) ;	// Data URI
        //   detail.bg_inputs = fileReader.result;
        //   console.log('a2', detail.bg_inputs);
        // }
        // // 読み込みを実行
        // fileReader.readAsDataURL( files[0] ) ;

      }
    },

    drag_start(e){
      e.item.style = "background-color:red;";
      // console.log('drag_start', e.item, e);
    },
    drag_end(e){
      e.item.style = "background-color:transparent;";
      const updDetails = [];
      this.details.forEach((detail, index) => {
        detail.sort = index;
        updDetails.push(detail);
      });
      this.details = updDetails;
      // console.log('end', this.lines);
    },
    drag_end2(e){
      console.log('end', e);

      e.item.style = "background-color:transparent;";
      // const updInputs = [];
      // this.details[idx].input_data.forEach((detail, index) => {
      //   detail.sort = index;
      //   updInputs.push(detail);
      // });
      // this.details[idx].input_data = updInputs;
      // console.log('end', this.lines);
    },
    tab_switch_action(tab) {
      this.tab_active = tab;
    },
    delForm(i){
      console.log('delForm', i, this.details);
      const targetDetails = this.details;
      const updDetails = [];
      if(targetDetails[i]){
      console.log('delForm', i, targetDetails[i]);
        delete(targetDetails[i]);
      }
      targetDetails.forEach((detail, index) => {
        detail.sort = index;
        updDetails.push(detail);
      });
      this.details = updDetails;
      console.log('delForm', i, this.details);
      // const tmp = this.details;
      // tmp.splice(i, 1);
      // this.details = tmp;
    },
    update(){
      this.template.details = this.details;
      console.log('component methods', this.template);
      return this.$store.dispatch('updateBasic', {data: this.template, target: 'template' })
        .then((res) => {
          console.log('template res', this.state);
            res => Promise.resolve(res);
            console.log('template then', this.targetid, res);
          // console.log('res update', res);
          if(res.data.body.id != undefined){
            this.targetid = res.data.body.id;
            this.$router.push({ path: '/template/' + res.data.body.id })
          }
          // this.back()
        })
        .finally(
          () => {
          const ret = !Number.isNaN(this.targetid)
            ? {...this.$store.getters.getBasicById(this.targetid, 'template')}
            : {};
            console.log('update finally', this.targetid, ret);
          alert(this.btnLabel + 'しました');
          // this.$router.push({ path: '/template/' + ret.parent_id });
          //   this.alertMessage = this.btnLabel + 'しました';
          //   this.alertClass = 'alert-success';
          //   Common.moveToTop();
          }
        )
        .catch(
          () => {
            Common.moveToTop();
            err => Promise.reject(err);
            this.alertMessage = this.btnLabel + 'できませんでした';
            this.alertClass = 'alert-danger';
          }
        )
    },
    del_list(detail_id, list_index){
      // this.details[detail_id].input_data[id] = ;
      console.log('del List', detail_id, list_index);
      if(this.details[detail_id].input_data[list_index]){
        const tmp = [];
        Object.keys(this.details[detail_id].input_data).forEach((val, index) => {
          console.log('deldeldel', index, val);
          if(list_index != index){
            tmp.push(this.details[detail_id].input_data[index]);
          }
        });
        this.details[detail_id].input_data = tmp;
        // delete(this.details[detail_id].input_data[list_index]);
        // this.details[detail_id].input_data.sort();
        console.log('deldel list ', this.details[detail_id]);
      }
    },
    add_list(detail_id, index){
      if(!this.detail_input_cnt[detail_id]){
        this.detail_input_cnt[detail_id] = 0;
      }
      this.detail_input_cnt[detail_id] += 1;
      console.log('add_list', detail_id);
      const id = 'add_input_' + String(detail_id) + '_' + String(this.detail_input_cnt[detail_id]);
      console.log('this.details[detail_id]', this.details[index]);
      // this.details[index].input_data[id] = {
      const tmp = {
        id: id,
        stat: 1,
        template_detail_id: detail_id,
        sort: this.detail_input_cnt[detail_id],
        deadline_add_day: 0,
        label: '',
        value: ''
      };
      this.details[index].input_data.push(tmp);
      console.log('add_list add_list', this.details[index], id, this.detail_input_cnt);
    },
    add_form(){
      this.detail_cnt += 1;
      const id = 'add_' + this.detail_cnt;
      console.log('add_liadd_formst', id);
      this.details.push({
        'id': id,
        'stat': 1,
        'template_id': this.targetid,
        order_detail_type: 0,
        sort: this.detail_cnt,
        subject: '',
        description: '',
        description_sw: false,
        input_data: [],
        bg_inputs: '',
        bg_inputs_json: ''
      });
    },
    loadOrderDetailTypeList () {
        // console.log('tag');
        return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'order_detail_type'})
                    .catch(err => Promise.reject(err))
                    .then(() => {
                        // console.log('loard complete device');
                        console.log('this order_detail_type', this.$store.state.apidata.order_detail_type);
                        // this.$router.push({ path: '/login' })
                    })
                    .finally(() => {
                        // console.log('finally', this.$store.state.discount);
                    })
    },
    loadOrdererList() {
        return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'orderer', targetF: 'select'})
                    .catch(err => Promise.reject(err))
                    .then(() => {
                        console.log('this', this.$store.state.apidata.orderer);
                    })
                    .finally(() => {
                        console.log('course finally', this.$store.state.apidata.orderer);
                    })
    },
    // 紐付きの発注先を取得
    loadContractorList() {
        return this.$store.dispatch('fetchBasicLists', {data: {}, target: 'contractor', targetF: 'select'})
                    .catch(err => Promise.reject(err))
                    .then(() => {
                        console.log('this', this.$store.state.apidata.contractor);
                    })
                    .finally(() => {
                        console.log('course finally', this.$store.state.apidata.contractor);
                    })
    },
    loadGetList (target_name) {
        // console.log('tag');
        return this.$store.dispatch('fetchBasicLists', {data: {}, target: target_name})
                    .catch(err => Promise.reject(err))
                    .then((res) => {
                      console.log('loadGetList', target_name, res);
                    })
                    .finally(() => {})
    },
  },
  computed: {
    ...mapState({
    // order: state => state.apidata.order,
      order_deadline_base: state => state.apidata.order_deadline_base,
      order_deadline_day_type: state => state.apidata.order_deadline_day_type,
      order_detail_input_value_type: state => state.apidata.order_detail_input_value_type,
      order_detail_type: state => state.apidata.order_detail_type,
      orderer_list: state => state.apidata.orderer,
      contractor_list: state => state.apidata.contractor,
    // order_template: state => state.apidata.template_id,
    }),
    detail_list_form_chk() {
      return (type) => {
        const typeV = parseInt(type);
        const not_list = [
          100,
          190,
          110,
          200,
          3000,
          3100,
          3200,
          5000,
          2000,
          2100,
          2200,
          10
        ];
        console.log('detail_list_form_chk', type, typeV, not_list, not_list.includes(typeV));
        if(typeV === 0 || not_list.includes(typeV) === true){
          return false;
        }else{
          return true;
        }
      }
    },
    tabDisplay: function () {
      const self_tab_active = this.tab_active;
      return function (tab, cls) {
        let tabClass = cls;
        if(self_tab_active == tab){
          tabClass += ' d-flex';
        }else{
          tabClass += ' d-none ';
        }
        return tabClass;
      }

    },
    tab_switch: function() {
      const self_tab_active = this.tab_active;
      return function (tab) {
        let tabClass = 'nav-link ';
        console.log('tab_switch', self_tab_active);
        if(self_tab_active == tab){
          tabClass += 'active';
        }
        return tabClass;
      }
    },
    // pen入力5000の場合
    detail_list_form_chk_pen_input_5000() {
      return (type) => {
        const typeV = parseInt(type);
        if(typeV >= 5000){
          return true;
        }
        return false;
      }
    },
    // pen入力は1000以上
    detail_list_form_chk_pen_input() {
      return (type) => {
        const typeV = parseInt(type);
        if(typeV >= 1000){
          return true;
        }
        return false;
      }
    },
    btnLabel() {
        if(this.add){
            return '新規登録';
        }
        else{
            return '更新';
        }
    },
  },
  created () {
    this.loadGetList('order_deadline_base');
    this.loadGetList('order_deadline_day_type');
    this.loadGetList('order_detail_input_value_type');
    this.loadOrderDetailTypeList();
    this.loadOrdererList();
    this.loadContractorList();
      if(Number.isNaN(this.targetid)){
        this.$router.push({ path: '/' })
      }
      const ret = !Number.isNaN(this.targetid)
        ? {...this.$store.getters.getBasicById(this.targetid, 'template')}
        : {};
      if(ret.stat == undefined){
        ret['stat'] = 1;
      }
      this.template = ret;
      this.details = [];
      console.log('created', this.template);
      console.log('created', ret.details);
      if(ret && ret.details != undefined && Object.keys(ret.details).length > 0){
        Object.keys(ret.details).forEach((index) => {
          if(ret.details[index].input_data != undefined && Object.keys(ret.details[index].input_data).length > 0){
            const input_tmp = [];
            Object.keys(ret.details[index].input_data).forEach((idx) => {
              console.log('ret.details[index].input_data' , idx, ret.details[index].input_data[idx]);
              input_tmp.push(ret.details[index].input_data[idx]);
            });
            ret.details[index].input_data = input_tmp;
            ret.details[index].description_sw = false;
            console.log('detail detail ', input_tmp);
            console.log('detail detail ret ', ret.details[index].input_data);
          }else{
            ret.details[index].input_data = [];
            ret.details[index].description_sw = false;
          }
          this.details.push(ret.details[index]);
        });
      }
      if(this.details){
        Object.keys(this.details).forEach((index) => {
          const detail = this.details[index];
          if((detail.input_data && Object.keys(detail.input_data).length <= 0) || !detail.input_data){
            // detail.input_data = {};
            this.details[index].input_data = [];
          }
        });
      }
      console.log('details = ', this.details, ret.details, ret);
      if(Object.keys(ret).length > 0){
        this.add = false;
      }
  },
  mounted: function(){
      // this.fromParentTitle = 'テンプレートd詳細';
      this.alertMessage = this.btnLabel + 'できます';
      // console.log(this.$route.path);
      // console.log('strage', this.$store.state.article);
      // console.log('this', ...this.$store.getters.getArticleById(1));
      this.fromParentTitle = 'テンプレートデータ';
      // console.log('tags_list', this.tags_list);
  },

  props: {
        displayClass: {
            type: String,
            default: 'col-md-9 ms-sm-auto col-lg-10 px-md-4'
        },
    },

}
</script>
