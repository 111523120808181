<template>
    <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <div class="container-fluid">
            <span class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">
                <a class="d-inline-block mx-1 px-2" @click="$emit('toggle')"> <i class="bi bi-list"></i></a>
                <span class="d-inline-block mx-1">DentalOrder</span>
            </span>
            <span class="col-md-9 col-lg-10 text-white px-2">
                {{auth.label}}
            </span>
            <ul class="navbar-nav px-3 d-none">
                <li class="nav-item text-nowrap">
                    <router-link
                        to="/logout"
                        class="nav-link">ログアウト</router-link>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
// import store from '../store'
// import { mapState } from 'vuex'
export default {
    name: 'header',
    data: function () {
        return {
            auth:  this.$store.state.auth,
            login: false
        }
    },
    computed: {
        authLogin () { // validを使ってログイン処理の可否、progressは後述
            if(!this.$store.state.auth || !this.$store.state.auth.token){
                return false;
            }else{
                return true;
            }
        }
    },
    mounted(){

    },
    methods:{
        hplus(){
            console.log('Home +', this.$store.state.auth, this.$store.state.auth.token);

            // this.$store.commit('Splus')
        },
        hminus(){
            this.$store.commit('Sminus')
        },
    },
}
</script>
