import client from './client'
import config from '../const'

export default {
    fetch: (token, {data, target, targetF}) => {
        return new Promise((resolve, reject) => {
            const params = new URLSearchParams();
            if(data != undefined){
                // console.log('data cccc', data);
                const keys = Object.keys(data);
                // console.log('keys cccc', keys);
                for(let i=0; i < keys.length; i++){
                    // console.log('ccchhhkkk', i, keys[i]);
                    if(keys[i] == 'p'){
                        params.append(keys[i], data[keys[i]]);
                    }else{
                        params.append('sprm[' + keys[i] + ']', data[keys[i]]);
                    }
                }
            }
            let targetPrefix = '';
            if(
                target == 'category'
                || target == 'type'
            ){
                targetPrefix = 'article_';
            }
            let targetSuffix = targetF;
            if(
                targetF == '' || targetF == undefined || targetF == null
            ){
                targetSuffix = 'lists';
            }
            console.log('basic fetch target', targetPrefix + target, config.BASE_API_URL + '/' + target + '/' + targetSuffix);
            client.post(config.BASE_API_URL + '/' + targetPrefix + target + '/' + targetSuffix, params, { withCredentials: true, headers: {[config.BASE_API_HEADER_KEY]: token } })
            .then(res => {
                console.log('basic fetch then ', target, {[target]: res.data.body});
                console.log('basic fetch then ', target, res);
                // resolve({ article: res.data.body, pager: {'article': res.data.pager} })
                const resolve_data = {
                    basic: res.data.body,
                    target: target,
                }
                console.log('res.data.pager', target, res.data.pager);
                if(res.data.pager){
                    resolve_data['pager'] = {
                        [target]: res.data.pager
                    }
                }
                console.log('res.data resolve_data', resolve_data);
                resolve(resolve_data)
            })
            .catch(err => {
                console.log('basic fetch catch error ', target, err);
                reject(new Error(err.response.data.message || err.message))
            })
        })
    },

    update: (token, { data, target }) => {
      return new Promise((resolve, reject) => {
        //   console.log('device update post data', data);
          const params = new URLSearchParams();
          let targetPrefix = '';
          if(
              target == 'category'
              || target == 'type'
          ){
              targetPrefix = 'article_';
          }
          let targetPath = '/' + targetPrefix + target + '/';
          // let sprm = {};
          if(data != undefined){
              const keys = Object.keys(data);
              for(let i=0; i < keys.length; i++){
                  console.log('iprm[' + keys[i] + ']', data[keys[i]]);
                  // sprm[keys[i]] = data[keys[i]];
                    if( data[keys[i]] !== null && typeof data[keys[i]] === 'object'){
                        params.append('iprm[' + keys[i] + ']', JSON.stringify(data[keys[i]]));
                    }else{
                        params.append('iprm[' + keys[i] + ']', data[keys[i]]);
                    }
              }
              console.log('update api data ', data);
              if(data.id != undefined && data.id != 'undefined'){
                  targetPath += 'edit/';
                  targetPath += data.id;
              }else{
                  targetPath += 'add';
              }
          }
          // console.log('params', params);
        client.post(config.BASE_API_URL + targetPath, params, { headers: {[config.BASE_API_HEADER_KEY]: token } })
          .then(res => {
              resolve(res);
                console.log('basic update then ', config.BASE_API_URL + targetPath, res);
            })
          .catch(err => {
              console.log(err);
            reject(new Error(err.response.data.message || err.message))
          })
      })
    },

}
